function createEventManager() {
    let bindings = []

    function bind(target, event, listener, capture = false) {
        try {
            target.addEventListener(event, listener, capture)
            bindings.push({ event, target, listener, capture })
        } catch (err) {}
    }

    function reset() {
        for (const binding of bindings) {
            try {
                binding.target.removeEventListener(binding.event, binding.listener, binding.capture)
            } catch (err) {} 
        }

        bindings = []
    }

    return { bind, reset }
}

export const EventManager = createEventManager()