import React, { forwardRef } from 'react'
import { Box, Text, VisuallyHidden } from '@chakra-ui/react'

function BaseErrorMessage({ message, ...elementProps }, ref) {
    if (!message) return null
    
    return (
        <Box aria-live="polite" ref={ref} role="alert" {...elementProps}>
            <VisuallyHidden>Error</VisuallyHidden>
            <Text color="states.error">{message}</Text> 
        </Box>
    )
}

export const ErrorMessage = forwardRef(BaseErrorMessage)