import { getCLS, getFID, getLCP } from 'web-vitals'
import { getElementSelector } from '../../internal/dom'

const TrackedVitals = {
    CLS: 'CLS',
    FID: 'FID',
    LCP: 'LCP'
}

// These are pretty reasonable thresholds that we should be able to meet without
// much effort while still be performance minded
const BaselineThresholds = {
    CLS: [0.1, 0.25],
    FID: [100, 300],
    LCP: [2500, 4000]
}

const RatingLabels = {
    1: 'Good',
    2: 'Needs Improvement',
    3: 'Poor'
}

export function observeWebVitals(measure) {
    const reporter = ({ delta, entries, id, name, value }) => {
        if (!value) {
            value = 0
        }
        
        measure({
            name: `web-vitals-${name}`,
            properties: {
                delta,
                entries,
                id,
                rating: getRatingLabel(name, value),
                ...getAdditionalInfo(name, entries),
            },
            value,
        })
    }

    getCLS(reporter)
    getFID(reporter)
    getLCP(reporter)
}

function getRatingLabel(name, value) {
    const [lower, upper] = BaselineThresholds[name]
    if (value > upper) {
        return RatingLabels[3]
    }
    return value > lower ? RatingLabels[2] : RatingLabels[1]
}

function getAdditionalInfo(name, entries) {
    let target

    // In some cases there won't be any entries. (e.g. if CLS is 0 or for LCP after a bfcache restore)
    if (entries.length) {
        switch (name) {
            case TrackedVitals.CLS:
                target = processCLS(entries)
            case TrackedVitals.FID:
                target = processFID(entries[0])
            case TrackedVitals.LCP:
                target = processLCP(entries[entries.length - 1])
            default:
                break
        }
    }

    return target ?? { debugTarget: 'notset' }
}

function processCLS(entries) {
    const largestShiftEntry = entries.reduce((a, b) => a && a.value > b.value ? a : b)
    if (largestShiftEntry && largestShiftEntry.sources?.length) {
        const largestShiftSource = largestShiftEntry.sources.reduce((a, b) => {
            return a.node && a.previousRect.width * a.previousRect.height > b.previousRect.width * b.previousRect.height
                ? a
                : b
        })
        if (largestShiftSource) {
            return {
                debugTarget: getElementSelector(largestShiftSource.node),
                eventTime: largestShiftEntry.startTime
            }
        }
    }
}

function processFID(firstEntry) {
    const [navigationEntry] = performance.getEntriesByType('navigation')

    return {
        debugEvent: firstEntry.name,
        debugTarget: getElementSelector(firstEntry.target),
        debugTiming: navigationEntry && firstEntry.startTime < navigationEntry.domContentLoadedEventStart
            ? 'Before DOM Content Loaded'
            : 'After DOM Content Loaded',
        eventTime: firstEntry.startTime
    }
}

function processLCP(lastEntry) {
    return {
        debugTarget: getElementSelector(lastEntry.element),
        eventTime: lastEntry.startTime
    }
}