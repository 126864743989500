export const Input = {
    baseStyle: {
        field: {
            border: "1px",
            borderColor: "brand.darkGrey",
            color: "brand.darkGrey",
            fontSize: ".75rem",
            fontWeight: "light",
            minHeight: "45px"
        }
    },
    sizes: {
        md: {
            field: {
                borderRadius: "8px",
                minHeight: "60px",
                px: 4
            }
        },
    },
    defaultProps: {
        size: "md"
    }
}