import React, { Fragment } from 'react'
import cn from 'classnames'

import { Header } from './Header'

export function Layout({ children, contentNode, isAuthenticated }) {
  const contentClasses = cn('c-app-content', {
    'is-authenticated': !!isAuthenticated
  })

  return (
    <Fragment>
        {isAuthenticated ? <Header/> : null}
        <div className={contentClasses} ref={contentNode}>
          {children}
        </div>    
    </Fragment>
  )
}