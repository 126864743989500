import React from 'react';
import {ErrorBoundary} from 'react-error-boundary';
import {useLocation} from 'react-router-dom';

import {analytics, logger} from 'core';
import {PrimaryButton} from 'components/elements';
import {DefaultErrorTemplate} from 'components/errors';

import {InvalidOriginErrorFallback} from './InvalidOriginErrorFallback';
import {NoOpenAccountErrorFallback} from './NoOpenAccountErrorFallback';

function AuthenticationErrorFallback({error, resetErrorBoundary}) {
  if (error.name === 'InvalidOriginForLoginError') {
    return <InvalidOriginErrorFallback error={error} resetErrorBoundary={resetErrorBoundary} />;
  }

  if (error.name === 'NoOpenAccountError') {
    return <NoOpenAccountErrorFallback error={error} resetErrorBoundary={resetErrorBoundary} />;
  }

  return (
    <DefaultErrorTemplate>
      <PrimaryButton onClick={resetErrorBoundary}>Try Again</PrimaryButton>
    </DefaultErrorTemplate>
  );
}

export function AuthenticationErrorBoundary({children}) {
  const location = useLocation();

  function onError(error, errorInfo) {
    analytics.exception({
      exception: error,
      properites: {
        currentLocation: location.pathname,
        errorInfo,
        errorName: error.name,
        errorProperties: error?.customProperties,
        isKnownError: !!error.isCustom,
      },
      severityLevel: logger.severityLevels.Critical,
    });
    logger.error(`Authentication Error Triggered Boundary`);
  }

  return (
    <ErrorBoundary FallbackComponent={AuthenticationErrorFallback} onError={onError}>
      {children}
    </ErrorBoundary>
  );
}
