import React, { forwardRef } from 'react'
import { Select, useMergeRefs } from '@chakra-ui/react'

const defaultOptionsStyles = {
    fontSize: '14px',
    fontWeight: 'light'
}

function BaseSelect({
    isInvalid,
    name,
    optionStyles = {},
    options,
    placeholder = "Please select",
    register,
    registerConfig,
    ...rest
}, ref) {

    let additionalProps = {}
    if (register && typeof register === 'function') {
        additionalProps = register(name, registerConfig)
    }

    const passedRef = useMergeRefs(ref, additionalProps?.ref)

    return (
        <Select
            iconColor={isInvalid ? 'brand.error' : 'brand.grey'}
            iconSize="25px"
            sx={{
                '& option': {
                    ...defaultOptionsStyles,
                    ...optionStyles
                }
            }}
            {...additionalProps}
            ref={passedRef}
            {...rest}
        >
            <option disabled value="">{placeholder}</option>
            {options.length > 0 && options.map(({ label = '', ...rest }) => (
                <option {...rest} key={label}>{label}</option>
            ))}
        </Select>
    )
}

export const SelectField = forwardRef(BaseSelect)