import {useMutation} from 'react-query'
import {analytics} from 'core'

import * as authAPI from './api'
import {authenticate, authTokenManager, unauthenticate} from './core'

export function useLoginMutation({mutationConfig = {}, showError} = {}) {
  return useMutation({
    mutationFn: ({password, rememberMe, username}) => {
      authTokenManager.updateRemberMeToken(rememberMe, username)
      return authAPI.login({password, username})
    },
    mutationKey: 'auth/login',
    onSuccess: (response, variables) => {
      if (response.canAuthenticate) {
        analytics.event('transaction/login', {
          ...response,
          rememberMe: variables?.rememberMe,
          username: variables.username,
        })
        authenticate(response.token, response.needsEConsent)
      } else if (response.isAccountLocked) {
        analytics.event('report/accountLocked', {
          ...response,
          username: variables.username,
        })
      } else if (response.success === false) {
        analytics.event('report/loginFailed', {
          ...response,
          username: variables.username,
        })
        showError()
      }
    },
    useErrorBoundary: true,
    ...mutationConfig,
  })
}

export function useRefreshMutation(onRefresh) {
  return useMutation({
    mutationFn: authAPI.refreshSession,
    mutationKey: 'auth/refreshSession',
    onError: error => {
      analytics.event('report/refreshSessionFailed', {error})
      unauthenticate(false, 'SessionExpirationModal')
    },
    onSuccess: () => {
      analytics.event('transaction/refreshSession')
      onRefresh()
    },
  })
}

export function useLogoutMutation(location) {
  return useMutation({
    mutationFn: authAPI.logout,
    mutationKey: 'auth/logout',
    onMutate: ({expired = false}) => {
      unauthenticate(expired, location)
    },
  })
}

export function useForgotPasswordMutation(mutationConfig = {}) {
  return useMutation({
    mutationFn: authAPI.forgotPassword,
    mutationKey: 'auth/forgotPassword',
    onSuccess: (data, variables, context) => {
      const eventName = !data.requestReceived
        ? 'report/forgotPasswordRequestFailure'
        : 'transaction/forgotPasswordRequest'

      analytics.event(eventName, {data, variables})
    },
    ...mutationConfig,
  })
}

export function useResetPasswordMutation({mutationConfig = {}, recoveryToken}) {
  return useMutation({
    mutationFn: async mutationData => {
      const {
        canAuthenticate,
        message = '',
        ...response
      } = await authAPI.resetPassword({...mutationData, recoveryToken})
      if (canAuthenticate) {
        analytics.event('transaction/resetPassword', response)
        authenticate(response.token, response.needsEConsent)
      } else {
        analytics.event('report/resetPasswordFailure', {
          ...response,
          recoveryToken,
        })

        return message
      }
    },
    mutationKey: 'auth/resetPassword',
    useErrorBoundary: true,
    ...mutationConfig,
  })
}



export function useUnlockAccountMutation({mutationConfig = {}, onSuccess}) {
  return useMutation({
    mutationFn: async mutationData => {
      const { token } = {...mutationData};

      const {
        payload,
        ...response
      } = await authAPI.unlockAccount({...mutationData, token});

      if (response.success) {
        analytics.event('transaction/unlockAccount', response);
        return true;
      } else {
        analytics.event('report/unlockAccountFailure', {
          ...response,
          token,
        });

        return false;
      }
    },
    mutationKey: 'auth/unlockAccount',
    useErrorBoundary: true,
    onSuccess: onSuccess,
    ...mutationConfig,
  });
}
export function useChangePasswordMutation(mutationConfig = {}) {
  return useMutation({
    mutationFn: async mutationData => {
      const {
        canAuthenticate,
        message = '',
        ...response
      } = await authAPI.changePassword(mutationData)
      if (canAuthenticate) {
        analytics.event('transaction/changePassword', response)
        authenticate(response.token, response.needsEConsent)
        return {message, response}
      } else {
        analytics.event('report/changePasswordFailure', response)
        return {message, response}
      }
    },
    mutationKey: 'auth/changePassword',
    ...mutationConfig,
  })
}
