import {SeverityLevel} from '@microsoft/applicationinsights-web';
import {v4 as uuid} from 'uuid';

import {exists, isEmpty} from '../utils';

const EventTypes = {
  Event: 'event',
  Measure: 'measure',
  Page: 'page',
  Report: 'report',
  Trace: 'trace',
};

const measureKeys = ['average', 'name', 'sampleCount', 'min', 'max', 'stdDev', 'iKey'];

export function eventBuilder(type, payload) {
  let constructedEvent = {};

  switch (type) {
    case EventTypes.Measure: {
      const {selected, extra} = pickPropertiesFromPayload(measureKeys, payload);
      const {measurements, properties} = pullSharedKeys(extra);
      constructedEvent = {
        metric: selected,
        measurements,
        properties: {
          data: properties,
          meta: {
            eventName: selected?.name,
            method: 'trackMetric',
          },
        },
      };
      break;
    }
    case EventTypes.Page: {
      const {name, state} = payload;
      constructedEvent = {
        name,
        refUri: state.previousScreenContext,
        properties: {
          data: {
            duration: performance.now() - state.viewStart,
            events: state.trackingBuffer,
          },
          meta: {
            method: 'trackPageView',
          },
        },
      };
      break;
    }
    case EventTypes.Report: {
      const {exception, severityLevel = SeverityLevel.Error, ...rest} = payload;
      const {measurements, properties} = payload;
      constructedEvent = {
        exception,
        measurements,
        severityLevel,
        properties: {
          data: properties,
          meta: {
            method: 'trackException',
          },
        },
      };
      break;
    }

    case EventTypes.Trace: {
      const {message, severityLevel = SeverityLevel.Information, ...rest} = payload;
      const {measurements, properties} = pullSharedKeys(rest);

      constructedEvent = {
        message,
        severityLevel,
        measurements,
        properties: {
          data: JSON.parse(
            JSON.stringify(properties).replace(/"\bpassword\b(.+?),/g, `\"password\\\":\\\"**********\,`),
          ),
          meta: {
            method: 'trackTrace',
          },
        },
      };

      break;
    }

    case EventTypes.Event: {
      const {name, ...eventData} = payload;
      const {measurements, properties} = pullSharedKeys(eventData);
      constructedEvent = {
        name,
        measurements,
        properties: {
          data: properties,
          meta: {
            eventName: name,
            method: 'trackEvent',
          },
        },
      };
      break;
    }
    default:
      throw new Error(`type: ${type} is not a supported method that event builder can parse`);
  }

  return {
    build: eventProcessor => {
      if (eventProcessor && typeof eventProcessor === 'function') {
        // Let this override instead of just returning it directly here so a consumer can repeatedly call this build
        // method to continually add additional context
        constructedEvent = eventProcessor(constructedEvent);
      }
      return constructedEvent;
    },
  };
}

function pickPropertiesFromPayload(properties, payload) {
  if (!Array.isArray(properties) || !payload || isEmpty(properties)) {
    return {};
  }

  const unqiueProps = new Set([...properties]);

  const payloadKeys = Object.keys(payload);
  if (isEmpty(payloadKeys)) {
    return {};
  }

  return payloadKeys.reduce(
    (acc, key) => {
      const entry = payload[key];
      if (unqiueProps.has(key)) {
        acc.selected[key] = entry;
      } else {
        acc.extra[key] = entry;
      }

      return acc;
    },
    {selected: {}, extra: {}},
  );
}

function pullSharedKeys(payload) {
  let measurements = {};
  let properties = {};

  if (exists(payload.measurements) && typeof payload.measurements === 'object') {
    measurements = payload.measurements;
  }

  if (exists(payload.properties) && typeof payload.properties === 'object') {
    if (payload.properties?.measurements && typeof payload.properties.measurements === 'object') {
      const {measurements: innerMeasure, ...otherProps} = properties;
      measurements = {...measurements, ...innerMeasure};
      properties = otherProps;
    } else {
      properties = payload.properties;
    }
  }

  return {measurements, properties};
}
