import React from 'react'
import { Box, Flex } from '@chakra-ui/react'
import { useBreakpoint } from 'hooks'

import { environment } from 'core'
import { LogoImage, PageBackground } from 'components/elements'

import { ErrorActions } from './ErrorActions'
import { ErrorBackdrop } from './ErrorBackdrop'
import { ErrorHeading } from './ErrorHeading'
import { ErrorText } from './ErrorText'

const innerContentStyles = {
    align: 'center',
    bg: { lg: 'rgba(255, 255, 255, 0.8)' },
    direction: 'column',
    h: '100%',
    margin: '0 auto',
    maxW: { base: '600px', lg: '80%', xl: '60%' },
    padding: { base: '1rem 1.5rem', lg: '2rem 5.125rem' },
    textAlign: 'center',
    w: '100%'
}

export function ErrorTemplate({ children, hasBackdrop }) {
    const isNotMobile = useBreakpoint('sm')
    const innerContent = (
        <Flex {...innerContentStyles}>
            {isNotMobile && <LogoImage />}
            {children}
        </Flex>
    )

    const renderedContent = hasBackdrop 
        ? <ErrorBackdrop>{innerContent}</ErrorBackdrop>
        : innerContent
    
    return (
        <PageBackground breakpoint="lg" src="login-background.jpg">
            {renderedContent}
        </PageBackground>
    )
}

ErrorTemplate.Actions = ErrorActions
ErrorTemplate.Heading = ErrorHeading
ErrorTemplate.Text = ErrorText