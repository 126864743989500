import React, { useMemo } from 'react'
import { Box } from '@chakra-ui/react'
import { environment } from 'core'

function constructImageProps(breakpoint, src) {    
    const [imageFile, extension] = src.split('.')
    const updatedExtension = environment.supportsWebP ? 'webp' : extension
    const modifiedSrc = `${process.env.PUBLIC_URL}/Content/assets/img/${imageFile}.${updatedExtension}`

    return {
        backgroundImage: {
            [breakpoint]: `url('${modifiedSrc}')`
        },
        backgroundRepeat: {
            [breakpoint]: 'no-repeat'
        },
        backgroundSize: {
            [breakpoint]: '100% 100%'
        },
    }
}

export function PageBackground({ breakpoint = 'base', children, src, ...rest }) {
    const { backgroundImage, backgroundRepeat, backgroundSize } = useMemo(() => {
        return constructImageProps(breakpoint, src)
    }, [breakpoint, src])

    return (
        <Box
            backgroundImage={backgroundImage}
            backgroundRepeat={backgroundRepeat}
            backgroundSize={backgroundSize}
            display="flex"
            h="100%"
            position="relative"
            {...rest}
        >
            {children}
        </Box>
    )
}