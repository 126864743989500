import {useMutation} from 'react-query';

import {analytics, axiosClient} from 'core';

export async function createSchedule({billingAccountNumber, contract, tokenId, paymentAmount, startDate}) {
  const response = await axiosClient.post('/acct/api/payment/schedule', {
    billingAccountNumber: billingAccountNumber,
    paymentAmount: paymentAmount,
    startDate: startDate,
    endDate: '',
    fundingTokenId: tokenId,
  });

  return response.data;
}

async function createScheduleMutation(data) {
  const {payload: schedule, success} = await createSchedule(data);

  if (success) {
    return {scheduleSuccessful: true};
  } else {
    return {scheduleSuccessful: false};
  }
  //TODO autopay analytics
  //   const userVisibleMessage = getErrorMessageForReason(declinedReason);
  //   analytics.event('diagnostic/makePaymentFailure', {
  //     ...errorPayload,
  //     userVisibleMessage,
  //   });
  //  return {message: userVisibleMessage, paymentSuccessful: false};
}

export function useCreateScheduleMutation(options) {
  return useMutation(createScheduleMutation, {
    useErrorBoundary: true,
    ...options,
  });
}
