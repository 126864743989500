import './shim'
import './bootstrap'

import React from 'react'
import { render } from 'react-dom'
import { BrowserRouter } from 'react-router-dom'

import { ServiceProvider } from 'context/service-provider'
import { App } from './App'

import Footer from "./components/layout/Footer";

const Application = () => (
  <BrowserRouter basename="/apps/account">
    <ServiceProvider>
      <App />
    </ServiceProvider>
  </BrowserRouter>
)

render(<Application />, document.getElementById('root'))
render(<Footer />, document.getElementById('footer'))

