import { useCallback } from 'react'
import { useFeatureSelector, } from 'core'

const FLAGS = {
    newACHPayment: 'newPaymentExperience'
}

export function useNewACHPayment() {
    const selector = useCallback(features => {
        const flag = features[FLAGS.newACHPayment]
        return flag && !!flag.enabled
    }, [])
    return true
    return useFeatureSelector(selector)
}

