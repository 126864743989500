import React from 'react'
import { useForm } from 'react-hook-form'
import {
    Box,
    Flex,
    Heading,
    Modal,
    ModalBody,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Text
} from '@chakra-ui/react'

import { ErrorMessage, Field, Form, OutboundLink, SubmitButton } from 'components/elements'
import { useEConsentMutation } from 'services/account'
import { clearConsentNeeded } from 'services/auth'

export function EConsentModal({ handleClose }) {
    const {
        formState: { errors, isDirty, isValid },
        getValues,
        handleSubmit,
        register,
        setValue
    } = useForm({
        defaultValues: {
            eDeliveryDisclosure: false,
            eSignDisclosure: false,
        },
        mode: 'onChange'
    })

    const { isLoading, mutate: eConsentMutation } = useEConsentMutation({
        onSuccess: () => {
            clearConsentNeeded()
            handleClose()
        }
    })

    const eDeliveryChecked = getValues().eDeliveryDisclosure
    const eConsentError = errors?.eSignDisclosure

    return (
        <Modal 
            closeOnEsc={false}
            closeOnOverlayClick={false}
            isOpen 
            onClose={handleClose}
            size="2xl"
        >
            <ModalOverlay bg="rgba(255, 255, 255, 0.8)" />
            <ModalContent 
                alignItems="center" 
                direction="column" 
                display="flex"
                my={{
                    base: '0',
                    lgPhone: '3.75rem'
                }}
            >
                <ModalHeader color="brand.blue">
                    <Heading>Electronic Consents</Heading>
                </ModalHeader>
                <ModalBody>
                    <Form onSubmit={handleSubmit(eConsentMutation)}>
                        <Flex mb="20px">
                            <Box pt="15px" pr="15px">
                                <Field
                                    field="checkbox"
                                    id="eConsent"
                                    isInvalid={eConsentError}
                                    size="lg"
                                    {...register("eSignDisclosure", {
                                        required: "You are required to give this consent"
                                    })}
                                />
                            </Box>
                            <Box>
                                <Text>
                                    By checking this box and clicking "Accept and Continue," I agree that I reviewed the {' '}
                                    <OutboundLink href="legal/esign-consent">Consent for Electronic Signatures and Records; {' '}</OutboundLink>
                                    confirm that I can view and keep the electronic records described there; and consent to the use and terms of electronic
                                    records and signatures as described there. Additionally, by checking this box and clicking "Accept and Continue", I agree that (i){' '}
                                    <b>I received, read, understood and consent to the</b> {' '}<OutboundLink href="legal/terms-of-use">Terms of Use</OutboundLink>
                                    {' '}(Including the {' '}<OutboundLink href="legal/terms-of-use#arbitration">AGREEMENT TO ARBITRATION</OutboundLink>{' '} (Section 15) and {' '}<OutboundLink href="legal/terms-of-use#juryTrial">JURY TRIAL WAIVER</OutboundLink>{' '}
                                    (Section 16)) contained therein and (ii) I received and read Republic Finance's {' '}<OutboundLink href="legal/privacy-policy">Online Privacy Policy</OutboundLink>{' '} and
                                    {' '}<OutboundLink href="legal/privacy-notice">U.S. Consumer Privacy Notice</OutboundLink>
                                </Text>
                                <ErrorMessage message={eConsentError?.message} />
                            </Box>
                        </Flex>
                        <Flex mb="20px">
                            <Box pt="15px" pr="15px">
                                <Field
                                    defaultChecked={eDeliveryChecked}
                                    field="checkbox"
                                    id="eDelivery"
                                    size="lg"
                                    {...register("eDeliveryDisclosure")}
                                    onChange={e => {
                                        setValue("eDeliveryDisclosure", !getValues().eDeliveryDisclosure)
                                    }}
                                />
                            </Box>
                            <Box>
                                <Text>
                                    By checking this box and clicking "Accept and Continue" you acknowledge your consent for Republic Finance to
                                    provider you requried disclosures regarding payment transfer attempts by using electronic delivery via email. You are not required to agree to
                                    electronic delivery of these federal payment transfer disclosures (Payment Disclosures). You may revoke E-Delivery Consent, and if 
                                    you revoke E-Delivery Consent then we will not provide Payment Disclosures electronically via email and you may no longer be elligible to set up
                                    recurring payments with Republic Finance. We will not deliver Payment Disclosures electronically if we receive notification that you are unable
                                    to receive Payment Disclosures through that delivery method at the address used.
                                </Text>
                            </Box>
                        </Flex>
                        <SubmitButton isDisabled={!isDirty || !isValid} isLoading={isLoading}>Accept and Continue</SubmitButton>
                    </Form>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}