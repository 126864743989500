import { useEffect, useRef } from 'react'
import { useMediaQuery, useToken } from '@chakra-ui/react'

export function useBreakpoint(breakpointKey, widthType = 'min-width') {
    const [breakpoint] = useToken('breakpoints', [breakpointKey])
    return useMediaQuery(`(${widthType}: ${breakpoint})`)[0]
}

export function useBreakpointEffect(key, effect) {
    const breakpoint = useBreakpoint(key)

    const effectRef = useRef(effect)
    effectRef.current = effect

    useEffect(() => {
        if (breakpoint) {
            effectRef.current?.()
        }
    }, [breakpoint])
}