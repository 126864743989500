import React from 'react'
import { Text as ChakraText, Heading as ChakraHeading } from '@chakra-ui/react'

export const Text = props => <ChakraText
    color="brand.darkGrey"
    fontFamily="body"
    fontSize="16px"
    fontWeight="light"
    my="5px"
    {...props}
/>

export const Disclosure = props => <ChakraText
    color="brand.grey"
    fontSize="12px"
    fontStyle="italic"
    lineHeight="16px"
    {...props}
/>

export const Heading = props => <ChakraHeading
    color="brand.blue"
    fontSize="27px"
    fontWeight={700}
    my="20px"
    {...props}
/>

export const Subheading = props => <ChakraHeading
    color="brand.grey"
    fontSize="22px"
    fontWeight={700}
    my="10px"
    {...props}
/>