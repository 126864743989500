import { logger } from '../analytics'

const cacheTime = 1000 * 60 * 10
const staleTime = 1000 * 60 * 30
let retryLimit = 3

function getRetryError(failureCount, error) {
    if (failureCount < retryLimit) {
        logger.error(`Retryable Query failed with error: ${error} after ${failureCount} tries.`, { error, failureCount })
        return true
    }
    return false
}

function shouldThrowToBoundary(error) {
    if (error?.response?.status === 500) {
        return true
    }
    if (error?._critical || error?.isCritical) {
        return true
    }

    return false
}


export function setRetryLimit(limit) {
    retryLimit = limit
}

export function getMutationDefaults() {
    return {
        useErrorBoundary: shouldThrowToBoundary
    }
}

export function getQueryDefaults() {
    return {
        cacheTime,
        keepPreviousData: true,
        refetchOnMount: true,
        refetchOnReconnect: false,
        refetchOnWindowFocus: true,
        retry: getRetryError,
        staleTime: staleTime,
        useErrorBoundary: shouldThrowToBoundary
    }
}

export const defaultClientOptions = {
    mutations: getMutationDefaults(),
    queries: getQueryDefaults()
}
