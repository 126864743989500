import React from 'react'
import { Link, Image } from '@chakra-ui/react'
import { environment } from 'core'

export function LogoImage({ isLight, ...rest }) {
    const fileExtension = environment.supportsWebP ? '.webp' : '.png'
    const imageSrc = `${process.env.PUBLIC_URL}/Content/assets/img/${isLight ? 'logo' : 'logo2'}${fileExtension}`

    return (
        <Link href="https://www.republicfinance.com/" rel="noopener noreferrer" target="_blank" title="Republic Finance">
            <Image alt="Republic Finance" h="110px" w="220px" src={imageSrc} {...rest} />            
        </Link>
    )
}
