export const defaultValidators = [{
    label: 'Minimum of 10 characters long',
    name: 'length',
    validate: s => s && s.length >= 10
}, {
    label: 'Must contain a lowercase character',
    name: 'lowercase',
    validate: s => /^(?=.*[a-z]).+$/.test(s)
}, {
    label: 'Must contain an uppercase character',
    name: 'uppercase',
    validate: s => /^(?=.*[A-Z]).+$/.test(s)
}, {
    label: 'Must contain a number and special character',
    name: 'special',
    validate: s => /(?=.*[^\w])(?=.*[0-9_]).*$/.test(s)
}]

