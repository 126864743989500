import React from 'react'
import { ErrorTemplate } from 'components/errors'
import { PrimaryButton, SecondaryButton, SupportPhoneLink } from 'components/elements'

import { useAppConfig } from 'context'

const buttonStyles = {
    flex: { lg: '1' },
    margin: { base: '.625rem 0', lg: '0 .625rem' },
    w: { base: '100%', lg: 'auto' }
}

export function InvalidOriginErrorFallback({ error, resetErrorBoundary }) {
    const websiteUrl = useAppConfig()
    const goToPayments = () => {
        window.location = `${websiteUrl}/payments`
    }

    return (
        <ErrorTemplate hasBackdrop>
            <ErrorTemplate.Heading>
                We're protecting your account
            </ErrorTemplate.Heading>
            <ErrorTemplate.Text>
                We've noticed that you are trying to access your Republic Finance online account from an unrecognized location.
                Please call us at <SupportPhoneLink lastDigit="4" /> to make a payment or if you have questions regarding your account.             
            </ErrorTemplate.Text>
            <ErrorTemplate.Text>Our team is available to assist Monday - Friday, 9am - 7pm EST.</ErrorTemplate.Text>
            <ErrorTemplate.Actions>
                <PrimaryButton onClick={goToPayments} {...buttonStyles}>View Payment Options</PrimaryButton>
                <SecondaryButton onClick={resetErrorBoundary} {...buttonStyles}>Back to Sign In</SecondaryButton>
            </ErrorTemplate.Actions>
        </ErrorTemplate>
    )
}