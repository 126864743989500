import React, { useCallback, useLayoutEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'

export function useScrollTop() {
    const location = useLocation()
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    }, [location])
}

export function useScrollTo(condition) {
    const elementRef = useRef(null)

    useLayoutEffect(() => {
        if (condition && elementRef.current) {
            const target = elementRef.current.getBoundingClientRect().top + window.scrollY
            window.scrollTo({
                behavior: 'smooth',
                top: target
            })
        }
    }, [condition])

    return useCallback(node => {
        elementRef.current = node
    }, [])
}

export function useScrollToError() {
    const [errorMessage, setErrorMessage] = useState('')
    const scrollToRef = useScrollTo(errorMessage)

    return [scrollToRef, setErrorMessage]
}