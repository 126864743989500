import { axiosClient } from 'core/httpClient'
import moment from 'moment'
export async function getPaymentProfile(contractNumber) {
  const response = await axiosClient.get(
    `/acct/api/payment/profile/${contractNumber}`,
  )
  return response.data.payload
}

export async function getFundingAccounts() {
  const response = await axiosClient.get('/acct/api/payment/methods')
  return response.data.payload
}

export async function getFundingUrl({
  billingAccountNumber,
  profileId,
  tokenId,
}) {
  const response = await axiosClient.post(`/acct/api/payment/methods`, {
    billingAccountNumber,
    profileId,
    tokenId,
  })
  return response.data.payload.fundingUrl
}

export async function makePayment({
  billingAccountNumber,
  contract,
  nachaStandardEntryClass = '',
  selectedPaymentAmount,
  tokenId,
  futureDatedPayment,
}) {
  const [branchId, accountNumber, contractNumber] =
    billingAccountNumber.split('-')

  const response = await axiosClient.post('/acct/api/payment', {
    accountNumber: accountNumber,
    amountDue: contract.collectAmount,
    balance: contract.balance,
    billingAccountNumber: billingAccountNumber,
    branchId: branchId,
    contractId: contract.contrId,
    companyId: contract.companyId,
    contractNumber: contractNumber,
    creditDebitIndicator: 'DEBIT',
    firstName: contract.firstName,
    fundingTokenId: tokenId,
    last4SSN: contract.customerLast4SSN,
    lastName: contract.lastName,
    nachaStandardEntryClass,
    namesId: contract.namesId,
    regularPayment: contract.regularPaymentAmount,
    remitAmount: selectedPaymentAmount,
    remitFee: '0.0',
    requestPaymentDate: !futureDatedPayment
      ? moment().format('YYYY-MM-DD')
      : moment(futureDatedPayment).format('YYYY-MM-DD'),
    source: '',
  })
  return response.data
}

export async function getPayments(contractNumber) {
  const response = await axiosClient.get(`/acct/api/payment/${contractNumber}`)
  return response.data.payload
}

export async function getFuturePaymentStatus(contractNumber) {
  const response = await axiosClient.get(
    `/acct/api/payment/futurePaymentStatus/${contractNumber}`,
  )
  return response.data.payload
}

export async function addBankFunding(payload) {
  const response = await axiosClient.post(
    '/acct/api/payment/methods/bank',
    payload,
  )
  return response.data.payload
}

export async function removeBankFunding(data) {
  const response = await axiosClient.delete('/acct/api/payment/methods', {
    data,
  })
  return response.data.payload
}

export async function getSchedule(contractNumber) {
  const schedules = await axiosClient.get(
    `/acct/api/payment/schedule/${contractNumber}`,
  )
  return schedules
}

export async function cancelAutoPay(contractNumber) {
  const response = await axiosClient.delete(
    `/acct/api/payment/schedule/${contractNumber}`,
  )
  return response
}
