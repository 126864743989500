import React from "react";

import { Form } from "components/elements";
import { useDidSessionExpire } from "services/auth/hooks";
import { useAppConfig } from "context";

const basicErrorMessage =
	"The information you entered does not match our records. Please try again.";
const inactivityMessage =
	"You have been signed out due to inactivity. Please log in again.";
const lockedErrorMessage =
	"For your security, we locked your account. Check your email for instructions on how to unlock it.";

const unlockSuccessMessage = "Your account was unlocked successfully.";
const unlockFailureMessage =
	"We encountered an issue unlocking your account.  Please try again later or call our Customer Support Team at (833) 907-1737.";
const stagedAccountMessage =
	"You haven't finished online enrollment. Follow the steps to enroll in online access.";

export function LoginFormError({
	hasError,
	isAccountLocked,
	unlockAccountSuccess,
	isAccountStaged,
}) {
	const isExpired = useDidSessionExpire();
	const { baseUrl } = useAppConfig();
	const errorMessage = (() => {
		if (isAccountLocked) return lockedErrorMessage;
		if (unlockAccountSuccess === false) return unlockFailureMessage;
		if (isExpired) return inactivityMessage;
		if (isAccountStaged) return stagedAccountMessage;
		if (hasError) return basicErrorMessage;
		if (unlockAccountSuccess === true) return unlockSuccessMessage;
		return "";
	})();

	return (
		<div>
			{errorMessage === stagedAccountMessage ? (
				<div style={{ color: "red" }}>
					You haven't finished online enrollment. Follow the steps to{" "}
					<a
						href={`${baseUrl}/enroll`}
						style={{
							color: "#2371a5",
							textDecoration: "underline",
						}}
					>
						enroll in online access.
					</a>{" "}
				</div>
			) : (
				<Form.Error message={errorMessage} />
			)}
		</div>
	);
}
