import { useMutation } from 'react-query'
import { analytics, axiosClient, CustomError } from 'core'
import { maskData } from 'helpers/maskData'
import moment from 'moment'
class PaymentAccountTokenMistmatchError extends CustomError {}
class PaymentBillingAccountError extends CustomError {}

const DeclineReasons = {
  ACISystemError: 'ACISystemError',
  BadBillingAccount: 'BadBillingAccount',
  CardFundingDeclined: 'CardFundingDeclined',
  DuplicatePayment: 'DuplicatePayment',
  InsufficientFunds: 'InsufficientFunds',
  InvalidPayment: 'InvalidPayment',
  InvalidFundingInfo: 'InvalidFundingInfo',
  ProfileMismatch: 'ProfileMismatch',
  TooManyPayments: 'TooManyPayments',
}

const ACISystemErrorCodes = new Set([2388, 3484, 34417])
const InvalidPaymentCodes = new Set([2304, 2429, 4437])

const getDeclinedReason = (code) => {
  if ((code >= 2499 && code <= 2506) || code === 25896) {
    return DeclineReasons.BadBillingAccount
  }

  if (code === 25845 || code === 25746) {
    return DeclineReasons.ProfileMismatch
  }

  if (ACISystemErrorCodes.has(code)) {
    return DeclineReasons.ACISystemError
  }

  if (InvalidPaymentCodes.has(code)) {
    return DeclineReasons.InvalidPayment
  }

  //ACI's High Risk ACH Accounts
  if (code === 2390) {
    return DeclineReasons.InvalidFundingInfo
  }

  if ((code >= 2393 && code <= 2395) || code === 4932) {
    return DeclineReasons.CardFundingDeclined
  }

  if (code >= 3615 && code <= 3618) {
    return DeclineReasons.DuplicatePayment
  }

  if (code === 3554) {
    return DeclineReasons.InsufficientFunds
  }

  if (code === 4258 || code === 4259) {
    return DeclineReasons.TooManyPayments
  }

  return 'Unknown'
}

const getErrorMessageForReason = (reason) => {
  switch (reason) {
    case DeclineReasons.CardFundingDeclined:
    case DeclineReasons.InsufficientFunds:
      return `Your bank declined this transaction. Please contact your bank or use another payment method.`
    case DeclineReasons.DuplicatePayment:
      return `You have a payment in process for this amount. To make an additional payment, use a different payment amount`
    case DeclineReasons.ACISystemError:
      return `We were unable to process your payment. Please try again later or contact us at (833) 907-1737`
    case DeclineReasons.InvalidFundingInfo:
      return `We were unable to use this bank account to process your payment. The routing or account number is invalid. Please try a different payment method or call 833-907-1737 for assistance.`
    default:
      return `We were unable to process your payment. Please try again later or contact us at (833) 907-1737`
  }
}

export async function makePayment({
  billingAccountNumber,
  contract,
  nachaStandardEntryClass = '',
  selectedPaymentAmount,
  futureDatedPayment,
  tokenId,
}) {
  const [branchId, accountNumber, contractNumber] =
    billingAccountNumber.split('-')

  const response = await axiosClient.post('/acct/api/payment', {
    accountNumber: accountNumber,
    amountDue: contract.collectAmount,
    balance: contract.balance,
    billingAccountNumber: billingAccountNumber,
    branchId: branchId,
    contractId: contract.contrId,
    companyId: contract.companyId,
    contractNumber: contractNumber,
    creditDebitIndicator: 'DEBIT',
    firstName: contract.firstName,
    fundingTokenId: tokenId,
    last4SSN: contract.customerLast4SSN,
    lastName: contract.lastName,
    nachaStandardEntryClass,
    namesId: contract.namesId,
    regularPayment: contract.regularPaymentAmount,
    remitAmount: selectedPaymentAmount,
    remitFee: '0.0',
    requestPaymentDate: !futureDatedPayment
      ? moment().format('YYYY-MM-DD')
      : moment(futureDatedPayment).format('YYYY-MM-DD'),
    source: '',
  })

  const {
    message: { messageCode, messageText = '' },
    ...payload
  } = response.data.payload
  const code = Number(messageCode)

  return {
    messageCode: code,
    messageText,
    ...payload,
  }
}

async function makePaymentMutation(data) {
  const paymentData = maskData(data)

  analytics.event('diagnostic/makePaymentStart', { paymentData })

  const {
    confirmationNumber = '',
    messageCode,
    messageText = '',
    ...response
  } = await makePayment(data)

  if (messageCode === 0) {
    analytics.event('payments/MakePayment', { confirmationNumber, paymentData })
    return { confirmationNumber, paymentSuccessful: true }
  }

  const declinedReason = getDeclinedReason(messageCode)
  const errorPayload = { declinedReason, messageCode, messageText, paymentData }

  if (declinedReason === DeclineReasons.BadBillingAccount) {
    throw new PaymentBillingAccountError(messageText, errorPayload)
  }

  if (declinedReason === DeclineReasons.ProfileMismatch) {
    throw new PaymentAccountTokenMistmatchError(messageText, errorPayload)
  }

  const userVisibleMessage = getErrorMessageForReason(declinedReason)

  analytics.event('diagnostic/makePaymentFailure', {
    ...errorPayload,
    userVisibleMessage,
  })

  return { message: userVisibleMessage, paymentSuccessful: false }
}

export function useMakePaymentMutation(options) {
  return useMutation(makePaymentMutation, {
    useErrorBoundary: true,
    ...options,
  })
}
