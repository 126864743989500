import React from 'react'
import { Flex } from '@chakra-ui/react'

import { ErrorMessage } from '../ErrorMessage'

export function Form({
    children,
    formRef,
    onSubmit,
    ...rest
}) {
    return (
        <Flex 
            align="center" 
            as="form" 
            direction="column" 
            onSubmit={onSubmit} 
            px={4}
            w="100%"
            ref={formRef}
            {...rest}
        >
            {children}
        </Flex>
    )
}
Form.Error = ErrorMessage