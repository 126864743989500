import { Button } from './Button'
import { Checkbox } from './Checkbox'
import { FormError } from './FormError'
import { FormLabel } from './FormLabel'
import { Input } from './Input'
import { Link } from './Link'

export const components = {
    Button,
    Checkbox,
    FormError,
    FormLabel,
    Input,
    Link
}
