import React from 'react'
import { CircularProgress } from '@chakra-ui/react'

export function LoadingSpinner({
    color = "#3c99d3",
    isIndeterminate = true,
    size="30px",
    thickness="2px",
    ...rest
}) {
    return (
        <CircularProgress
            color={color}
            isIndeterminate={isIndeterminate}
            size={size}
            thickness={thickness}
            {...rest}
        />
    )
}