import {useMutation} from 'react-query';

import {openACIModal} from 'services/ui';

import {analytics, axiosClient, logger, queryClient} from 'core';
import {localStorageHelper} from 'helpers';

function storePropertiesBeforeACIRedirect(billingAccountNumber, tokenId) {
  const selectedContract = queryClient.getQueryData([
    'contract',
    billingAccountNumber,
  ]);
  const fundingAccounts = queryClient.getQueryData(['fundingAccounts']);

  logger.info(
    `Storing current contract and funding accounts before ACI Redirect`,
    {
      fundingAccounts,
      selectedContract,
      tokenId,
    },
  );

  localStorageHelper.setItem(
    'aciRedirectInfo',
    JSON.stringify({fundingAccounts, selectedContract, tokenId: tokenId ?? ''}),
  );
}

async function sendModifyDebitFundingRequest(fundingRequest) {
  const response = await axiosClient.post('/acct/api/payment/methods', {
    ...fundingRequest,
    source: 'web',
  });
  return response.data.payload.fundingUrl;
}

async function handleModifyDebitFundingRequest(fundingRequest) {
  analytics.event(
    `diagnostic/request${
      fundingRequest.tokenId != null ? 'Edit' : 'Add'
    }/DebitFunding`,
    {fundingRequest},
  );

  let modifyUrl = queryClient.getQueryData(['debitFundingURL', fundingRequest]);

  if (modifyUrl) {
    logger.info(
      `Grabbed Modify Debit Funding URL from a restored offline cache`,
      {
        fundingRequest,
        modifyUrl,
      },
    );
  } else {
    try {
      modifyUrl = await sendModifyDebitFundingRequest(fundingRequest);
      queryClient.setQueryData(['debitFundingURL', fundingRequest]);
    } catch (error) {
      logger.error(`Failed to fetch the modify debit URL`, error);
    }
  }

  if (modifyUrl) {
    storePropertiesBeforeACIRedirect(
      fundingRequest.billingAccountNumber,
      fundingRequest.tokenId,
    );

    localStorageHelper.setItem('aciRedirectUrl', JSON.stringify(modifyUrl));

    openACIModal(modifyUrl);
    // window.location = modifyUrl;
    return modifyUrl;
  } else {
    return {status: 'ERROR'};
  }
}

export function useAddDebitMutation(fundingRequest, mutationOptions = {}) {
  return useMutation(
    () =>
      handleModifyDebitFundingRequest({
        ...fundingRequest,
        tokenId: null,
      }),
    mutationOptions,
  );
}

export function useEditDebitMutation(fundingRequest, mutationOptions = {}) {
  return useMutation(
    () => handleModifyDebitFundingRequest(fundingRequest),
    mutationOptions,
  );
}
