import { HubConnectionBuilder } from "@microsoft/signalr"

export function initializeSocketConnection(url) {
    if (!url) {
        return null
    }
    
    const connection = new HubConnectionBuilder()
        .withUrl(url)
        .build()
    
    return connection.start().then(() => connection)
}