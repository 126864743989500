import { logger } from '../analytics'
import { Subscribable } from '../utils'

class OnlineManager extends Subscribable {
    constructor() {
        super()

        this.onlineStatus = navigator?.onLine ?? true

        addEventListener('online', this.onStatusChange, false)
        addEventListener('offline', this.onStatusChange, false)
    }

    getStatus() {
        if (this.onlineStatus) {
            return this.onlineStatus
        }

        return navigator?.onLine ?? true
    }

    onStatusChange(onlineStatus) {
        logger.info('Users online status changed', {
            previousStatus: this.onlineStatus,
            currentStatus: onlineStatus
        })
        
        this.onlineStatus = onlineStatus
        this.notify(listener => {
            listener(onlineStatus)
        })
    }
}

export const onlineManager = new OnlineManager()