import {
    useCallback,
    useLayoutEffect,
    useRef 
} from 'react'

// Creates a mutable reference to a function that only notifies consumers
// of a change when its dependencies change
export function useCallbackRef(fn, dependencies = []) {
    const functionRef = useRef(fn)

    useLayoutEffect(() => {
        functionRef.current = fn
    })

    return useCallback(
        (...args) => functionRef.current?.(...args),
        dependencies
    )
}
