export function omit(object, names) {
    if (!names) {
        throw new Error(`omit requires a names argument as a second argument`)
    }
    if (!Array.isArray(names)) {
        throw new TypeError(`omit expects names to be an Array. Received: ${typeof names}`)
    }

    if (!object) {
        return null
    }
    
    const keys = new Set(names)
    
    return Object.entries(object).reduce((acc, [name, value]) => {
        if (!keys.has(name)) {
            acc[name] = value
        }
        return acc
    }, {})
}

export function noop(...args) {}

export function countTruthyValues(values = []) {
    return values.reduce((sum, value) => sum + (value ? 1 : 0), 0)
}

export function exists(item) {
    return item !== undefined && typeof item !== 'undefined' && item != null
}

export function isEmpty(arr) {
    return arr.length === 0
}

export function isPromiseLike(obj) {
    return typeof obj === 'object' && typeof obj.then === 'function'
}

export function replaceNaN(data, replacement) {
    return typeof data === 'number' && isNaN(data) ? replacement : data
}