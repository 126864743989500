import {useMutation} from 'react-query';
import {analytics, logger} from 'core';

import * as accountAPI from './api';

export function useEConsentMutation({onSuccess}) {
  return useMutation({
    mutationFn: mutationData =>
      accountAPI.verifyEConsent({
        ...mutationData,
        termsAndPrivacyDisclosure: true,
      }),
    mutationKey: 'account/provideEConsent',
    onMutate: variables => {
      logger.info(`User Attempting to provide EConsent`);
    },
    onSuccess: (data, variables) => {
      analytics.event('transaction/EConsent', {
        properties: {
          consents: variables,
        },
      });
        sessionStorage.setItem('needsEDelivery', variables.eDeliveryDisclosure !== true);
        onSuccess(data);
    },
  });
}

export function useEDeliveryMutation({onSuccess}) {
  return useMutation({
    mutationFn: mutationData =>
      accountAPI.updateEDelivery({
        ...mutationData,
      }),
    mutationKey: 'account/provideEDelivery',
    onMutate: variables => {
      logger.info(`User Attempting to provide EDelivery`);
    },
    onSuccess: (data, variables) => {
      analytics.event('transaction/EDelivery', {
        properties: {
          consents: variables,
        },
      });
        onSuccess(data);
        sessionStorage.setItem('needsEDelivery', false);
    },
  });
}
