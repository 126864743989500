export const MODAL_TYPES = {
  ACHPayment: 'ACHPayment',
  EConsentModal: 'EConsentModal',
  NotificationModal: 'NotificationModal',
  BlankNotificationModal: 'BlankNotificationModal',
  PaymentTypeSelection: 'PaymentTypeSelection',
  RedirectToACI: 'RedirectToACI',
  SessionExpiration: 'SessionExpiration',
  ACIModal: 'ACIModal',
  EDeliveryConsentModal: 'EDeliveryConsentModal',
};
