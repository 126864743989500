
export const Button = {
    baseStyle: {
        background: 'none',
        border: 'none',
        fontWeight: 'light',
    },
    sizes: {
        large: {
            height: "60px",
        }
    },
    variants: {
        link: props => ({
            color: "brand.mediumBlue",
            fontSize: "1rem",
            _hover: {
                color: "brand.lightBlue",
                textDecoration: "underline"
            }
        }),
        "medium-blue": {
            background: "brand.mediumBlue",
            color: "white",
            fontWeight: "light",
            fontSize: "22px",
            _hover: {
                background: "brand.lightBlue",
                _disabled: {
                    background: "states.disabled"
                }
            },
            _disabled: {
                background: "states.disabled",
                _hover: {
                    background: "states.disabled"
                }
            }
        }
    },
    defaultProps: {
        size: "large",
        variant: "medium-blue"
    }
}