import React from 'react'
import { Flex } from '@chakra-ui/react'

const actionGroupStyles = {
    align: 'center',
    direction: { base: 'column', lg: 'row' },
    justify: { lg: 'space-between' },
    mt: { lg: '2rem' },
    maxW: '650px',
    w: '100%'
}

export function ErrorActions({ children, ...rest }) {
    return <Flex {...actionGroupStyles} {...rest}>{children}</Flex>
}