export const FormLabel = {
    baseStyle: {
        color: "brand.darkGrey",
        fontSize: "1rem",
        fontWeight: "medium",
        opacity: 1,
        transition: "all 0.2s",
        _disabled: {
            opacity: 0.4
        }
    }
}