import create from 'zustand';
import {MODAL_TYPES} from './constants';

const INITIAL_COMPONENT_PROPS = {};

const modalState = create((get, set) => ({
  componentProps: INITIAL_COMPONENT_PROPS,
  path: null,
}));

export function openModal(path, componentProps = INITIAL_COMPONENT_PROPS) {
  if (!path) return;
  if (!MODAL_TYPES[path]) return;
  modalState.setState({componentProps, path});
}

export function closeModal() {
  modalState.setState({
    componentProps: INITIAL_COMPONENT_PROPS,
    path: null,
  });
}

export function useModalState(...args) {
  return modalState(...args);
}

export function useModal(type) {
  if (!MODAL_TYPES[type]) {
    throw new Error(`There is no matching modal named ${type} in ${Object.keys(MODAL_TYPES).join('\n')}`);
  }

  return componentProps => openModal(type, componentProps);
}

export function openEConsentModal(componentProps = {}) {
  openModal(MODAL_TYPES.EConsentModal, componentProps);
}

export function openNotificationModal(componentProps = {}) {
  openModal(MODAL_TYPES.NotificationModal, componentProps);
}

export function openPaymentTypeSelectionModal(componentProps = {}) {
  openModal(MODAL_TYPES.PaymentTypeSelection, componentProps);
}

export function openACIModal(componentProps = {}) {
  openModal(MODAL_TYPES.ACIModal, componentProps);
}

export function openEDeliveryConsentModal(componentProps = {}) {
  openModal(MODAL_TYPES.EDeliveryConsentModal, componentProps);
}
export function openBlankNotificationModal(componentProps = {}) {
  openModal(MODAL_TYPES.BlankNotificationModal, componentProps);
}
