export function collectBrowserProperties(passedCookieFromCaller) {
    return {
        cookiesEnabled: areCookiesEnabled(passedCookieFromCaller),
        documentLastModified: document?.lastModified,
        plugins: getPlugins(),
        timezone: getTimezone()
    }
}

function areCookiesEnabled(passedCookieFromCaller) {
    if (passedCookieFromCaller) {
        return true
    }

    document.cookie = `cookietest=1; SameSite=Strict`
    const result = document.cookie.indexOf('cookietest=') !== -1

    document.cookie = `cookietest=1; SameSite=Strict; expires=Thu, 01-Jan-1970 00:00:01 GMT`
    return result
}

function getTimezone() {
    const DateTimeFormat = window.Intl?.DateTimeFormat
    if (DateTimeFormat) {
        const timezone = new DateTimeFormat().resolvedOptions().timeZone
        if (timezone) {
            return timezone
        }
    }

    const currentYear = new Date().getFullYear()
    const offset = Math.max(
        parseFloat(new Date(currentYear, 0, 1).getTimezoneOffset()),
        parseFloat(new Date(currentYear, 6, 1).getTimezoneOffset())
    )

    return `UTC${(-offset) >= 0 ? '+' : ''}${Math.abs((-offset))}`
}

function getPlugins() {
    const rawPlugins = navigator.plugins
    if (!rawPlugins) {
        return undefined
    }

    const plugins = []

    for (const plugin of rawPlugins) {
        if (!plugin) {
            continue
        }

        const mimeTypes = []
        for (let i = 0; i < plugin.length; i++) {
            const mimeType = plugin[i]
            mimeTypes.push({
                suffixes: mimeType.suffixes,
                type: mimeType.type
            })
        }

        plugins.push({
            description: plugin.description,
            mimeTypes,
            name: plugin.name
        })
    }

    return plugins
}
