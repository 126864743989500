function tryParse(item) {
    if (!item) {
        return null
    }

    let parsed = null

    try {
        parsed = JSON.parse(item)
    } catch (error) {}

    return parsed
}

function createStorageHelper(storage) {
    return {
        getItem(key) {
            const item = storage.getItem(key)
            return tryParse(item)
        },
        setItem(key, value) {
            if (value == null) {
                storage.removeItem(key)
            } else {
                storage.setItem(key, JSON.stringify(value))
            }
        },
        removeItem(key) {
            storage.removeItem(key)
        }
    }
}

export const localStorageHelper = createStorageHelper(window.localStorage)
export const sessionStorageHelper = createStorageHelper(window.sessionStorage)
