import React from 'react'
import { Circle, Flex, Spacer } from '@chakra-ui/react'

export function IconHeader({ color, icon }) {
    const horizontalRow = <Spacer alignSelf="center" bg={color} h="2px" />
    return (
        <Flex align="center" w="100%">
            {horizontalRow}
            <Circle bg="transparent" border="2px" borderColor={color} mx={2} size="60px">
                {icon}
            </Circle>
            {horizontalRow}
        </Flex>
    )
}