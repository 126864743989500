import React from 'react'
import { useHistory } from 'react-router-dom'

import {
  Button,
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  IconButton,
  useDisclosure
} from '@chakra-ui/react'
import { HamburgerIcon } from '@chakra-ui/icons'

const buttonStyles = {
  alignItems: 'center',
  borderBottom: "1px solid",
  borderColor: "brand.lightGrey",
  borderRadius: '0',
  color: "brand.blue",
  fontSize: "18px",
  fontWeight: "light",
  height: "55px",
  justifyContent: 'flex-start',
  outline: 'none',
  py: '8px',
  px: '8px',
  variant: 'link',
  _hover: {
    color: "brand.mediumBlue"
  }
}

const iconButtonStyles = {
  background: 'transparent',
  color: 'brand.blue',
  _hover: {
    color: 'brand.mediumBlue'
  },
  w: '60px'
}

export function MobileMenu({ links }) {
  const { onClose, onOpen, isOpen } = useDisclosure()
    
  const history = useHistory()
  const navigateTo = path => {
      history.push(path)
      onClose()
  }

  return (
    <Flex align="center">
      <IconButton icon={<HamburgerIcon boxSize="35px" />} onClick={onOpen} {...iconButtonStyles} />
      <Drawer isOpen={isOpen} onClose={onClose} placement="left">
        <DrawerOverlay />
        <DrawerContent>
            <DrawerCloseButton color="brand.blue" bg="transparent" border="none"/>
            <Flex direction="column" pt="30px">
              {links.map(link => {
                const onClick = typeof link.onClick === 'function' ? link.onClick : () => navigateTo(link.path)
                return <Button key={link.text} onClick={onClick} {...buttonStyles}>{link.text}</Button>
              })}
            </Flex>
        </DrawerContent>
      </Drawer>
    </Flex>
  )
}