import React from 'react'
import { Fade } from '@chakra-ui/react'

import { useForgotPasswordMutation } from 'services/auth'

import { PasswordRecoveryHeader } from '../components'

import { RequestResetForm } from './RequestResetForm'
import { ResetInstructions } from './ResetInstructons'

import './index.css'

export function ForgotPassword() {
    const { data, error, isLoading, isSuccess, mutate, reset: resetMutationState } = useForgotPasswordMutation()
    
    return (
        <div className="c-forgot-password">
            <PasswordRecoveryHeader />
            <div className="c-forgot-password__content">
                <Fade in={!isSuccess} unmountOnExit>
                    <RequestResetForm 
                        error={error}
                        isLoading={isLoading} 
                        requestReset={mutate}
                        resetMutationState={resetMutationState}
                    />
                </Fade>
                <Fade in={isSuccess} unmountOnExit>
                    <ResetInstructions username={isSuccess && data.username} />
                </Fade>                 
            </div>         
        </div>
    )
}