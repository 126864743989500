const DEFAULT_MIN_TASK_TIME = 0
const MAX_PRIORITY_EVENT = 10000

const isSafari = !!(typeof safari === 'object' && safari.pushNotification)

const defaultSortFn = (a, b) => {
    if (!a.priority && !b.priority) return 0
    if (a.priority && (!b.priority || a.priority === MAX_PRIORITY_EVENT)) return 1
    if (b.priority && (!a.priority || b.priority === MAX_PRIORITY_EVENT)) return -1 
    return a.priority - b.priority
}

export class IdleQueue {
    constructor({
        defaultMinTaskTime = DEFAULT_MIN_TASK_TIME,
        ensureTasksRun = false,
        sortFunction = defaultSortFn
    }) {
        this.defaultMinTaskTime = defaultMinTaskTime
        this.ensureTasksRun = ensureTasksRun
        this.sortFunction = defaultSortFn

        this.idleHandle = null
        this.isProcessing = false
        this.isPriorityQueue = false
        this.maximumBatchSize = null
        this.state = null
        this.taskQueue = []

        this.runTasks = this.runTasks.bind(this)
        this.runTasksImmediately = this.runTasksImmediately.bind(this)
        this.onVisibilityChange = this.onVisibilityChange.bind(this)

        if (this.ensureTasksRun) {
            addEventListener('visibilitychange', this.onVisibilityChange, true)
            if (isSafari) {
                addEventListener('beforeunload', this.runTasksImmediately, true)
            }
        }
    }

    addTask(arrayMethod, task, { minTaskTime = this.defaultMinTaskTime, priority = -1 } = {}) {
        const state = {
            time: Date.now(),
            visibilityState: document.visibilityState
        }
        const envelope = { minTaskTime, state, task }

        if (priority && priority >= 0) {
            this.isPriorityQueue = true
            envelope.priority = priority
            
            // Max priority event always takes precedence based on taking the latest max priority event.
            if (priority === MAX_PRIORITY_EVENT) {
                this.taskQueue.sort(this.sortFunction)
                this.taskQueue.unshift(envelope)

                this.scheduleTasksToRun()
                return
            }
        }

        arrayMethod.call(this.taskQueue, envelope)
        
        if (this.isPriorityQueue) {
            this.taskQueue.sort(this.sortFunction)
        }

        this.scheduleTasksToRun()
    }

    pushTask(...args) {
        this.addTask(Array.prototype.push, ...args)
    }

    unshiftTask(...args) {
        this.addTask(Array.prototype.push, ...args)
    }

    runTasks(deadline = undefined) {
        this.cancelScheduledExecution()

        if (!this.isProcessing) {
            this.isProcessing = true

            let count = 0
            const withinSizeConstraint = Number.isInteger(this.maximumBatchSize) ? count <= this.maximumBatchSize : true

            while (this.hasPendingTasks() && !this.shouldYield(deadline, this.taskQueue[0].minTaskTime) && withinSizeConstraint) {
                const { state, task } = this.taskQueue.shift()
                this.state = state
                task(state)

                count++
                this.state = null
            }

            this.isProcessing = false
            this.maximumBatchSize = null

            if (this.hasPendingTasks()) {
                this.scheduleTasksToRun()
            }
        }
    }

    // Flushes the queue and runs all scheduled tasks synchronously
    runTasksImmediately(count) {
        if (count && Number.isInteger(count)) {
            this.maximumBatchSize = count
        }
        this.scheduleTasksToRun()
    }

    scheduleTasksToRun() {
        if (this.ensureTasksRun && document.visibilityState === 'hidden') {
            queueMicrotask(this.runTasks)
        } else {
            if (!this.idleHandle) {
                this.idleHandle = window.requestIdleCallback(this.runTasks)
            }
        }
    }

    cancelScheduledExecution() {
        window.cancelIdleCallback(this.idleHandle)
        this.idleHandle = null
    }

    destroy() {
        this.taskQueue = []
        this.cancelScheduledExecution()

        if (this.ensureTasksRun) {
            removeEventListener('visibilitychange', this.onVisibilityChange, true)
            if (isSafari) {
                removeEventListener('beforeunload', this.runTasksImmediately, true)
            }
        }
    }

    getState() {
        return this.state
    }

    hasPendingTasks() {
        return this.taskQueue.length > 0
    }

    onVisibilityChange() {
        if (document.visibilityState === 'visible') {
            this.runTasksImmediately()
        }
    }

    shouldYield(deadline, minTaskTime) {
        if (deadline && deadline.timeRemaining() <= minTaskTime) {
            return true
        }
        return false
    }
}