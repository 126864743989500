import {Telemetry} from '@microsoft/applicationinsights-web';
import {ConsoleLogger} from '@microsoft/signalr/dist/esm/Utils';
import Emittery from 'emittery';
import {v4 as uuid} from 'uuid';

import {IdleQueue} from '../utils';
import {eventBuilder} from './event-builder';
import {createInsights, createUserManager, EventManager} from './internal';
import {createLogger} from './logger';
import {performancePlugin} from './plugins';

const identity = v => v;

export function createAnalyticsCore() {
  let initialized = false;
  let instance;
  let internalLogger;
  let userManager;

  let inactivityCounter = 0;
  let inactivityTime = 20;
  let sessionStartTimestamp = 0;
  let lastEventTimestamp = 0;
  let lastActiveTime = 0;
  let lastViewTime = 0;
  let storedDuration = 0;
  let storedLastViewTime = 0;

  let pendingEvents = [];
  let scheduledTasks = [];
  let lazyQueue = new IdleQueue({ensureTasksRun: true});
  let emitter = new Emittery();

  // Breadcrumbs are essentially a collection of strings logged by logger. Whenever an exception is triggered the current breadcrumbs are automatically flushed with that error event
  // to give a better view into what might have lead to that outcome. Breadcrumbs can also be flushed manually on any telemetry. Manual flushes come in two flavors: soft and hard. A soft
  // flush leaves the breadcrumbs structure untouched after reading it's contents. A hard flush empties the array. Really soft is more of a "peek" since it doesn't flush.
  let breadcrumbs = [];

  let state = {
    currentScreenContext: '',
    isTrackingTime: false,
    isTransitioning: false,
    previousScreenContext: null,
    registeredPlugins: [],
    trackingBuffer: {},
    viewStart: 0,
  };

  function initialize(config, history) {
    sessionStartTimestamp = Math.round(Date.now() / 1000);

    return createInsights(config, history).then(insights => {
      instance = insights;
      userManager = createUserManager(instance, event);

      attachNavigationListener(history);

      flushQueued();
      flushTasks();

      initialized = true;
    });
  }

  function getInstance() {
    return instance;
  }

  function getState() {
    return state;
  }

  function setLogger(loggerInstance) {
    internalLogger = loggerInstance;
  }

  function event(name, eventPayload, flush = false) {
    const builder = eventBuilder('event', {name, ...eventPayload});
    processItem(builder.build(flush ? flushBreadcrumbs : identity));
  }

  function exception(payload) {
    const builder = eventBuilder('report', payload);
    processItem(builder.build(flushBreadcrumbs));
  }

  function measure({value, ...rest}, flush = false) {
    const builder = eventBuilder('measure', {average: value, ...rest});
    processItem(builder.build(flush ? flushBreadcrumbs : identity));
  }

  function trace(message, payload, flush = false) {
    const builder = eventBuilder('trace', {message, properties: payload});
    processItem(builder.build(flush ? flushBreadcrumbs : identity));
  }

  function trackPage(name, options) {
    if (state.isTransitioning) {
      console.warn(`Tried calling track page while transitioning pages`);
      return;
    }

    if (state.currentScreenContext !== '') {
      state.previousScreenContext = state.currentScreenContext;
    }

    state.currentScreenContext = name;
    state.trackingBuffer = {};
    state.viewStart = performance.now();

    // Here is where we should refresh any plugins/trackers as well
    if (options?.modifyPlugins) {
      const newPlugins = options.modifyPlugins(state.registeredPlugins);
      state.registeredPlugins = [];

      newPlugins.map(plugin => registerPlugin(plugin));
      for (const plugin of state.registeredPlugins) {
        plugin.refresh();
      }
    }

    return () => {
      state.isTransitioning = true;

      // If the plugin has the capability to update the trackingBuffer internally
      // then we need to make sure we flush all the pending buffer updates from each
      // of those plugins so the page view will have them tracked in its events and
      // will be fresh for the next page
      flushPluginBuffers();
      const builder = eventBuilder('page', {name, state});
      processItem(builder.build());
    };
  }

  function attachInactiveListeners() {
    startTrackingTime();

    // Flush all of the buffers before window unload
    EventManager.bind(window, 'beforeunload', () => {
      flushPluginBuffers();
      flushQueued();
      flushTasks();
    });

    function onVisibilityChange() {
      if (document.hidden || !document.hasFocus()) {
        stopTrackingTime();
      } else {
        startTrackingTime();
      }
    }

    EventManager.bind(window, 'focus', onVisibilityChange, true);
    EventManager.bind(window, 'blur', onVisibilityChange, true);
    EventManager.bind(window, 'pageshow', onVisibilityChange, true);
    EventManager.bind(window, 'pagehide', () => {
      onVisibilityChange();
      // Safari doesn't respect the before unload event, so we trigger this on pagehide as well
      flushPluginBuffers();
    });

    if ('hidden' in document) {
      EventManager.bind(document, 'visibilitychange', onVisibilityChange, true);
    }
  }

  function startTrackingTime() {
    if (!state.isTrackingTime) {
      state.isTrackingTime = true;
      lastActiveTime = Math.round(Date.now() / 1000) - storedDuration;
      lastViewTime = Math.round(Date.now() / 1000) - storedLastViewTime;
      storedLastViewTime = 0;
    }
  }

  function stopTrackingTime() {
    if (state.isTrackingTime) {
      state.isTrackingTime = false;
      storedDuration = Math.round(Date.now() / 1000) - lastActiveTime;
      storedLastViewTime = Math.round(Date.now() / 1000) - lastViewTime;
    }
  }

  function addBreadcrumb(crumb) {
    breadcrumbs.push(crumb);

    // If we have more than 60 breadcrumbs currently active, go ahead and force flush them
    // otherwise this could really eat into our event payload
    if (breadcrumbs.length > 60) {
      event(
        'size-limit/breadcrumbs',
        {
          currentScreenContext: state.currentScreenContext,
          previousScreenContext: state.previousScreenContext,
        },
        true,
      );
    }
  }

  // @TODO: This should probably have a max limit.
  function flushBreadcrumbs(telemetryItem, hard = true) {
    let formattedString = '';
    for (const crumb of breadcrumbs) {
      if (!crumb) continue;

      if (typeof crumb === 'string') {
        formattedString += `${crumb} \n`;
        continue;
      }

      if (typeof crumb === 'object') {
        const {message, severityLevel = '', ...rest} = crumb;

        formattedString += ` ${message}:${severityLevel} -- ${JSON.stringify(rest, null, 2)} \n`;
        formattedString.replace(/"\bpassword\b(.+?),/, ' ');
      }
    }

    if (!telemetryItem.properties.data) {
      telemetryItem.properties.data = {};
    }

    telemetryItem.properties.data.breadcrumbs = formattedString;

    // Empty array on hard flush
    if (hard) {
      breadcrumbs = [];
    }

    telemetryItem = JSON.parse(
      JSON.stringify(telemetryItem).replace(/"\bpassword\b(.+?),/g, `\"password\\\":\\\"**********\,`),
    );

    return telemetryItem;
  }

  function registerPlugin(plugin) {
    const registeredPlugin = plugin({
      emitter,
      getInstance,
      getState,
      queueTask,
      updateBuffer,
      flushQueued,
      flushTasks,
      event,
      exception,
      measure,
      trace,
      userManager,
      setLogger,
    });

    if (registeredPlugin?.refresh && typeof registeredPlugin.refresh === 'function') {
      registeredPlugin.refresh();
    }

    state.registeredPlugins.push(registeredPlugin);
  }

  function flushPluginBuffers(shouldRefreshPlugins = false) {
    if (state.registeredPlugins.length > 0) {
      for (const plugin of state.registeredPlugins) {
        if (typeof plugin?.flush === 'function') {
          plugin.flush();
        }

        if (shouldRefreshPlugins && typeof plugin?.refresh === 'function') {
          plugin.refresh();
        }
      }
    }
  }

  // This is a little clever. Initialize the subscription outside of the listener
  // and then cleanup from the original subscription and initialize a new one AFTER a transition already happens
  // Unfortunately the only way to do this PRE transition is to monkey patch the window history methods
  // and we don't need to be that fine grained yet. Over the course of the users session we
  // are recording their navigation timing events so we can we determine fairly well
  // how much to correct the view duration to compensate for the running a little late.
  function attachNavigationListener(history) {
    let subscription = trackPage(window.location.pathname);
    history.listen(location => {
      subscription();
      state.isTransitioning = false;
      subscription = trackPage(window.location.pathname);
    });
  }

  function processItem(telemetryItem) {
    const {properties} = telemetryItem;

    const method = properties.meta.method;

    telemetryItem.properties.meta.transactionId = uuid();
    telemetryItem.properties.meta.eventId = uuid();

    const eventTimestamp = Math.round(Date.now() / 1000) - state.viewStart;
    telemetryItem.properties.meta.clientProcessedTimestamp = eventTimestamp;

    const {currentSession, currentUser} = userManager.getState();

    telemetryItem.properties.user = {
      ...(telemetryItem.properties.user || {}),
      businessId: currentUser.accountId,
      domainSessionId: currentSession.domainSessionId,
      domainUserId: currentUser.domainUserId,
      isCookieSet: !!currentUser.domainUserId,
      isNewUser: currentUser.isNewUser,
    };

    if (!lastEventTimestamp) {
      telemetryItem.properties.user.firstEventOfSession = true;
    } else {
      telemetryItem.properties.meta.timeBetweenEvents = eventTimestamp - lastEventTimestamp;
    }

    lastEventTimestamp = performance.now();
    queueTask(() => {
      instance[method](telemetryItem);
    });
  }

  function updateBuffer(updates = {}) {
    const updateKeys = Object.keys(updates);
    if (updateKeys.length > 0) {
      for (const key of updateKeys) {
        const isArrayLike = Array.isArray(updates[key]);

        if (!(key in state.trackingBuffer)) {
          state.trackingBuffer[key] = isArrayLike ? [] : {};
        }

        state.trackingBuffer[key] = isArrayLike
          ? [...state.trackingBuffer[key], ...(updates[key] || [])]
          : {...state.trackingBuffer[key], ...(updates[key] || {})};
      }
    }
  }

  function queueTask(task) {
    if (!initialized) {
      pendingEvents.push(task);
    } else {
      lazyQueue.pushTask(task);
    }
  }

  function scheduleTask(task) {
    scheduledTasks.push(task);
  }

  function flushQueued() {
    if (!initialized && pendingEvents.length > 0) {
      for (const event of pendingEvents) {
        lazyQueue.pushTask(event);
      }
      pendingEvents = [];
    } else if (initialized && lazyQueue.hasPendingTasks()) {
      lazyQueue.runTasksImmediately();
    }
  }

  function flushTasks() {
    if (scheduledTasks.length > 0) {
      for (const task of scheduledTasks) {
        setTimeout(() => {
          task();
        }, 0);
      }
    }
  }

  return {
    // Provide logger directly on the exported interface
    get logger() {
      return internalLogger;
    },
    initialize,
    getInstance,
    event,
    exception,
    measure,
    registerPlugin,
    trace,
    trackPage,
    addBreadcrumb,
    scheduleTask,
    setAuthedContext: user => userManager.authenticate(user),
    clearAuthedContext: (expired, from) => userManager.unauthenticate(expired, from),
    setLogger,
  };
}

export const analytics = createAnalyticsCore();
export const logger = createLogger(analytics);

export async function initializeAnalytics(config, history) {
  await analytics.initialize(config, history);
  analytics.registerPlugin(performancePlugin);
}
