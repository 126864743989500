import { initializeAnalytics } from './analytics'
import { initializeConfig } from './config'
import { createHttpClient } from './httpClient'

import { includeHotjar } from './utils'

export async function initializeCore(passedContext) {
    const { configURL, globalHistory } = passedContext
    const { config, features } = await initializeConfig(configURL)


    const httpClient = createHttpClient(config.env.localEnvironmentHost)

    await initializeAnalytics(config, globalHistory)
    
    function afterInit() {
        let timeout = setTimeout(() => {
            if (config?.useHotjar) {
                const instance = includeHotjar()
                instance('identify')
            }

            clearTimeout(timeout)
        }, 0)
    }

    return {
        afterInit,
        config,
        features,
        httpClient
    }
}