/**
 * Unforunate shim file. We should really be doing feature detection over the wire on the fly, but we aren't there yet
 */

class IdleDeadline {
    constructor(initTime) {
        this.initTime = initTime
    }

    get didTimeout() {
        return false
    }

    timeRemaining() {
        return Math.max(0, 50 - (Date.now() - this.initTime))
    }
}

window.requestIdleCallback = window.requestIdleCallback || function(callback) {
    const deadline = new IdleDeadline(Date.now())
    return setTimeout(() => callback(deadline), 0)
}

window.cancelIdleCallback = window.cancelIdleCallback || function(handle) {
    clearTimeout(handle)
}