import { replaceNaN } from '../../utils'

export function collectHardware() {
    return {
        cpuClass: navigator.cpuClass,
        deviceMemory: replaceNaN(parseFloat(navigator.deviceMemory), undefined),
        hardwareConcurrency: replaceNaN(parseInt(navigator.hardwareConcurrency), undefined),
        mediaConstraints: getMediaConstraints(),
        osCpu: navigator.osCpu ?? undefined,
        webGL: getWebGLDebugInfo()        
    }
}

function getMediaConstraints() {
    if (!navigator?.mediaDevices) {
        return undefined
    }

    if (typeof navigator.mediaDevices.getSupportedConstraints === 'function') {
        return navigator.mediaDevices.getSupportedConstraints()
    }

    return undefined
}

function getWebGLDebugInfo() {
    const canvas = document.createElement('canvas')
    const context = canvas.getContext('webgl')
    
    if (context == null) {
        return undefined
    }

    const debugInfo = context.getExtension('WEBGL_debug_renderer_info')
    if (debugInfo == null) {
        return undefined
    }

    const vendor = context.getParameter(debugInfo.UNMASKED_VENDOR_WEBGL)
    const renderer = context.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL)

    return { renderer, vendor }    
}