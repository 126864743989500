const simpleShortIdKey = 'device-id'

export function createSimpleShortId() {
    let currentToken = window.localStorage.getItem(simpleShortIdKey)
    if (currentToken) {
        try {
            return JSON.parse(currentToken)
        } catch (error) {}
    }

    currentToken = (window.crypto.getRandomValues(new Uint32Array(1))[0]).toString(16)
    window.localStorage.setItem(simpleShortIdKey, JSON.stringify(currentToken))
    return currentToken
}