import { useMutation } from 'react-query'
import { axiosClient, queryClient } from 'core'

async function removeBankFunding(data) {
    const response = await axiosClient.delete('/acct/api/payment/methods', { data })
    const { message: { messageCode, messageText }, ...payload } = response.data.payload
    
    return {
        messageCode: Number(messageCode),
        messageText,
        ...payload
    }
}


async function removeBankFundingMutation(data) {
    const { messageCode, messageText, ...response } = await removeBankFunding(data)
    
    if (messageCode === 0) {
        queryClient.setQueryData(['fundingAccounts'], (prev = []) => {
            return prev.filter(({ tokenId }) => tokenId !== data.tokenId)
        })
        queryClient.invalidateQueries(['fundingAccounts'])

        return { status: 'SUCCESS' }
    }

    return { status: 'FAILURE' }
}

export function useRemoveBankFundingMutation(options) {
    return useMutation(removeBankFundingMutation, options)
}