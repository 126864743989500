import React from 'react';
import {ErrorTemplate} from 'components/errors';
import {PrimaryButton} from 'components/elements';

import {useAppConfig} from 'context';

export function NoOpenAccountErrorFallback() {
  const {baseUrl} = useAppConfig();

  const navigateToOnlineApplication = () => {
    window.location = `${baseUrl}/applyv2/login`;
  };

  return (
    <ErrorTemplate hasBackdrop>
      <ErrorTemplate.Heading>No Accounts Added Yet</ErrorTemplate.Heading>
      <ErrorTemplate.Text>
        Your online application is not complete yet. You can click the button
        below to login and continue with your loan application.
      </ErrorTemplate.Text>
      <ErrorTemplate.Actions>
        <PrimaryButton onClick={navigateToOnlineApplication}>
          Go To Online Application
        </PrimaryButton>
      </ErrorTemplate.Actions>
    </ErrorTemplate>
  );
}
