export function isDefined(value) {
    return typeof value !== 'undefined' && value !== undefined
}
export function exists(value) {
    return isDefined(value) && value != null
}

export function isObject(value) {
    return exists(value) && (typeof value === 'object' || typeof value === 'function') && !Array.isArray(value)
}

export function isEmptyObject(value) {
    return isObject(value) && Object.keys(value).length === 0
}