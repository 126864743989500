import React from 'react'
import { Modal } from 'react-bootstrap'

const navigateToACH = () => {
    window.open(
        "https://web.paymentvision.com/republicfinance/default.aspx",
        "_blank"
    )    
}

export function ACHPaymentModal({ handleClose }) {
    return (
        <Modal show onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title align="center">
                    You are leaving the Republic Finance Website
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div align="center" className="row">
                    <div className="col-xs-12 col-md-12">
                        Our trusted partner, Payment Vision, will take your bank account
                        information and process your electronic payment.
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button
                    className="btn btn-danger"
                    style={{ color: "#fff" }}
                    onClick={handleClose}
                >
                    Cancel
                </button>
                <button
                    className="btn btn-success"
                    style={{ color: "#fff" }}
                    onClick={navigateToACH}
                >
                    Continue
                </button>
            </Modal.Footer>              
        </Modal>
    )
}