import React, { Fragment, cloneElement } from 'react'

import { SupportPhoneLink } from 'components/elements'
import { ErrorTemplate } from './ErrorTemplate'

function DefaultSupportLink() {
    return (
        <Fragment>
            If the problem persists, please call us directly at <SupportPhoneLink />
        </Fragment>
    )
}

export function DefaultErrorTemplate({
    children,
    hasBackdrop = true,
    heading = "Oops! We've run into an issue",
    subheading = "We are currently experiencing technical difficulties. Please wait a few minutes and try logging in again.",
    supportLink = DefaultSupportLink
}) {
    let SupportLinkComponent = supportLink

    return (
        <ErrorTemplate hasBackdrop={hasBackdrop}>
            {heading && (
                <ErrorTemplate.Heading>{heading}</ErrorTemplate.Heading>
            )}
            {subheading && (
                <ErrorTemplate.Text>{subheading}</ErrorTemplate.Text>
            )}
            {supportLink && (
                <ErrorTemplate.Text><SupportLinkComponent /></ErrorTemplate.Text>
            )}
            {children && (
                <ErrorTemplate.Actions>
                    {children}
                </ErrorTemplate.Actions>
            )}
        </ErrorTemplate>
    )
}