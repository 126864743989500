import React, { forwardRef } from "react";
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  InputGroup,
  InputRightElement,
  IconButton,
} from "@chakra-ui/react";

import { getElementFromFieldName } from "./getElementFromFieldName";

const getErrorMessage = (error) => {
  if (error) {
    if (typeof error === "string") {
      return error;
    }

    return error?.message;
  }

  return "";
};

function BaseField(
  {
    children,
    controlProps = {},
    error,
    field = "input",
    id,
    isDisabled,
    label,
    name,
    type,
    ...elementProps
  },
  ref
) {
  const fieldName = field.toLowerCase();
  const isCheckbox = fieldName === "checkbox";
  const isPassword = type === "password";
  const Element = getElementFromFieldName(fieldName);

  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);

  return (
    <FormControl
      id={id}
      isDisabled={isDisabled}
      isInvalid={!!error}
      {...controlProps}
    >
      {label && (
        <FormLabel textStyle="input-label" style={{ fontSize: "16px" }}>
          {label}
        </FormLabel>
      )}
      <InputGroup>
        <Element
          background={isCheckbox ? "transparent" : "white"}
          errorBorderColor="states.error"
          focusBorderColor="brand.lightBlue"
          name={name}
          ref={ref}
          type={isPassword ? (show ? "text" : "password") : type}
          {...elementProps}
        >
          {children}
        </Element>
        {isPassword && (
          <InputRightElement width="4.5rem" top="9px">
            <IconButton
              id="passwordEye"
              size="lg"
              colorScheme="black"
              alt="passwordeyeball"
              aria-label="passwordeyeball"
              variant="ghost"
              onClick={handleClick}
              icon={
                show ? (
                  <i className="fas fa-eye-slash"></i>
                ) : (
                  <i className="fas fa-eye"></i>
                )
              }
            />
          </InputRightElement>
        )}
      </InputGroup>
      <FormErrorMessage color="states.error">
        {getErrorMessage(error)}
      </FormErrorMessage>
    </FormControl>
  );
}

export const Field = forwardRef(BaseField);
