async function loadConfig(url) {
    try {
        const response = await fetch(url)
        return response.json()
    } catch (error) {
        return {}
    }
}

function formatConfig(passedConfig = {}) {
    const { buildEnv = 'dev', localEnvironmentHost = 'localhost', ...rest } = passedConfig
    return {
        ...rest,
        env: {
            buildEnv,
            localEnvironmentHost,
            isRunningTests: process.env.NODE_ENV === 'test'
        }
    }
}

export async function fetchBaseConfig(url) {
    const config = await loadConfig(url)
    return formatConfig(config)
}