import { logger } from '../analytics'
import { Subscribable } from '../utils'

class ConnectionManager extends Subscribable {
    constructor() {
        super()

        const connection = navigator && (navigator.connection || navigator.mozConnection || navigator.webkitConnection)
        
        if (connection && typeof connection?.addEventListener === 'function') {
            this.connection = connection
            this.connection.addEventListener('change', this.onConnectionChange)
        } else {
            this.connection = undefined
        }
    }

    getConnection() {
        return this.connection
    }

    onConnectionChange = (connectionInfo) => {
        logger.info(`Users connection info changed`, {
            currentConnection: connectionInfo,
            previousConnection: this.connection
        })

        this.previousConnection = this.connection
        this.connection = { ...this.connection, ...connectionInfo }
        this.notify(listener => {
            listener(this.connection)
        })
    }
}

export const connectionManager = new ConnectionManager()
