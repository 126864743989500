import { useQuery } from 'react-query'
import { axiosClient, logger, queryClient } from 'core'

export async function getPaymentProfile(contractNumber) {
    const response = await axiosClient.get(`/acct/api/payment/profile/${contractNumber}`)
    return response.data.payload
}

function createPaymentProfileQuery(contractNumber) {
    return {
        queryFn: () => getPaymentProfile(contractNumber),
        queryKey: ['paymentProfile', contractNumber]
    }
}

export function triggerPaymentProfilePrefetch(contractNumber) {
    logger.verbose(`Triggered prefetch of payment profile`, { contractNumber })
    queryClient.prefetchQuery(createPaymentProfileQuery(contractNumber))
}

export function usePaymentProfile(contractNumber, options = {}) {
    return useQuery({
        ...createPaymentProfileQuery(contractNumber),
        retry: 5,
        ...options
    })
}