import React from 'react'
import { Button } from '@chakra-ui/react'

import { useAppConfig } from 'context'

// @TODO: Outside link navigation tracking in insights
const useGoToSignup = () => {
    const { baseUrl } = useAppConfig()

    return () => {
        window.location = baseUrl + '/enroll'
    }
}

export function SignupButton({ children, link, ...styleProps }) {
    const goToSignup = useGoToSignup()
    return <Button onClick={goToSignup} variant={link ? "link" : "medium-blue"} {...styleProps}>{children}</Button>
}