import React, { Suspense, lazy, useRef } from 'react';
import { ChakraProvider } from '@chakra-ui/react'

import { FullScreenLoader, Layout } from 'components'
import { GlobalErrorBoundary } from 'components/errors'
import { ModalRenderer } from 'components/modals'
import { globalTheme } from 'theme'

import { loadLazyComponent } from 'core'
import { useIsAuthenticated } from 'services/auth/hooks'
import { AuthenticationPages } from 'pages/authentication'


const AuthenticatedApp = lazy(() => loadLazyComponent(() => import('./AuthenticatedApp')))

export function App() {
    const contentNode = useRef(null)
    const isAuthenticated = useIsAuthenticated()

    return (
        <ChakraProvider theme={globalTheme} resetCSS={!isAuthenticated}>
            <Layout contentNode={contentNode} isAuthenticated={isAuthenticated}>
                <GlobalErrorBoundary>
                    <ModalRenderer />
                    <Suspense fallback={<FullScreenLoader />}>
                        {isAuthenticated ? <AuthenticatedApp /> : <AuthenticationPages />}
                    </Suspense>                    
                </GlobalErrorBoundary>
            </Layout>         
        </ChakraProvider>
    )
}
