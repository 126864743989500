import React, { Fragment } from 'react'

import { useModal, MODAL_TYPES } from 'services/ui'
import { PrimaryButton } from 'components/elements'
import { RedirectToDebitButton } from './RedirectToDebitButton'

export function StandardPaymentSelection({
    isLoading,
    redirectToDebit
}) {
    const openACHPaymentModal = useModal(MODAL_TYPES.ACHPayment)

    return (
        <Fragment>
            <div className="col-xs-1 col-md-2"></div>
            <div className="col-xs-5 col-md-4">
                <PrimaryButton
                    fontSize={{ base: '12px', md: '14px' }}
                    onClick={openACHPaymentModal}
                    size="md"
                    width="auto"
                >
                    Pay with Bank Account
                </PrimaryButton>
            </div>
            <div className="col-xs-5 col-md-4">
                <RedirectToDebitButton isLoading={isLoading} redirectToDebit={redirectToDebit} />
            </div>
            <div className="col-xs-1 col-md-2"></div>
        </Fragment>
    )
}