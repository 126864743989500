import React from 'react';
import {Flex} from '@chakra-ui/react';

import {useBreakpoint} from 'hooks';

import {LogoImage} from '../elements';
import {HeaderMenu} from './HeaderMenu';

export function Header() {
  const isNotMobile = useBreakpoint('sm');
  return (
    <>
      <Flex
        align="center"
        borderBottom="1px solid"
        borderColor="brand.lightGrey"
        direction={isNotMobile ? 'row-reverse' : 'row'}
        justify="space-between"
        px="7%"
      >
        <HeaderMenu isNotMobile={isNotMobile} />

        <LogoImage />
      </Flex>
    </>
  );
}
