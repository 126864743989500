import { logger } from '../analytics'

export function loadLazyComponent(lazyComponent, retryLimit = 5, retryInterval = 1500) {
    return new Promise((resolve, reject) => {
        lazyComponent()
            .then(resolve)
            .catch(error => {
                logger.error(`Ran into error loading lazy component chunk`, error)
                setTimeout(() => {
                    if (retryLimit === 1) {
                        reject(error)
                        return
                    }
                    loadLazyComponent(lazyComponent, retryLimit - 1, retryInterval).then(resolve, reject)
                }, retryInterval)
            })
    })
}