import { collectBrowserProperties } from "./browser-properties"
import { collectConnectedDevices } from "./connected-devices"
import { collectDisplay } from "./display"
import { collectHardware } from "./hardware"
import { collectUserAgent } from './user-agent'

let initialProperties

export async function collectInitialProperties() {
    if (initialProperties) {
        return initialProperties
    }

    const connectedDevices = await collectConnectedDevices()
    const properties = {
        browser: collectBrowserProperties(),
        connectedDevices,
        device: collectUserAgent(),
        display: collectDisplay(),
        hardware: collectHardware(),
        useragent: navigator.userAgent
    }

    initialProperties = properties
    return properties
}

