import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useLocation } from 'react-router-dom'

import { analytics, hotjarInstance, logger } from 'core'

import { GlobalErrorFallback } from './GlobalErrorFallback'

export function GlobalErrorBoundary({ children }) {
    const location = useLocation()

    function onError(error, info) {
        hotjarInstance('event', 'triggered-global-exception')
        analytics.exception({
            exception: error,
            properties: {
                currentLocation: location.pathname,
                errorInfo: info,
                isKnownError: !!error.isCustom
            },
            severityLevel: logger.severityLevels.Critical,

        })
        logger.error('Unhandled Exception triggered oops page')
    }

    return (
        <ErrorBoundary FallbackComponent={GlobalErrorFallback} onError={onError}>
            {children}
        </ErrorBoundary>
    )
}