import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Link as ChakraLink } from "@chakra-ui/react";

import { useAppConfig } from "context";

export function Link({ children, ...rest }) {
  return (
    <ChakraLink as={RouterLink} className="c-link" {...rest}>
      {children}
    </ChakraLink>
  );
}

export function OutboundLink({
  bold = true,
  children,
  href,
  openNewTab = true,
  ...rest
}) {
  const { websiteUrl } = useAppConfig();

  let isDifferentOrigin = false;

  function formatHref() {
    const expression = /(http|https):\/\//;
    // If the passed href is already absolute, then it probably isn't for the main www site
    // so we can safely return it
    if (expression.test(href)) {
      if (!/republicfinance/.test(href)) {
        isDifferentOrigin = true;
      }

      return href;
    }

    const normalizedHref = href.startsWith("/") ? href : `/${href}`;
    if (expression.test(websiteUrl)) {
      return `${websiteUrl}${normalizedHref}`;
    }

    return `https://${websiteUrl}${normalizedHref}`;
  }

  const anchorProps = {
    color: "brand.lightBlue",
    href: formatHref()
  };

  if (openNewTab) {
    anchorProps.target = "_blank";
    if (isDifferentOrigin) {
      anchorProps.rel = "noopener noreferrer";
    }
  }

  if (bold) {
    anchorProps.fontWeight = "bold";
  }

  return (
    <ChakraLink {...anchorProps} {...rest}>
      {children}
    </ChakraLink>
  );
}

const startingPhoneNumberString = "(833) 907-173";

export function SupportPhoneLink({ lastDigit = "7", ...props }) {
  const phoneNumber = startingPhoneNumberString + lastDigit;
  return (
    <ChakraLink
      className="c-link c-support-link"
      href={`tel:${phoneNumber}`}
      {...props}
      id="customerSupportPhoneNumber"
    >
      {phoneNumber}
    </ChakraLink>
  );
}
