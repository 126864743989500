import create from 'zustand';

import {analytics, createTokenManager, logger, queryClient} from 'core';

import {NoOpenAccountError} from './errors';

export const authTokenManager = createTokenManager({
  needsConsent: {key: 'needsConsent', storage: window.sessionStorage},
  rememberMe: {key: 'rememberMe', storage: localStorage},
  session: {key: 'sessionToken', storage: window.sessionStorage},
});

export const useAuthSlice = create((get, set) => ({
  authenticated: false,
  currentUser: null,
  expired: false,
  expiresAt: null,
}));

export function createAuthSubscription(subscription) {
  return useAuthSlice.subscribe(subscription);
}

export const authenticate = (token, needsConsent = false) => {
  if (!token) {
    logger.error(`Tried to authenticate without a token`);
    return;
  }

  const currentUser = authTokenManager.decodeToken(token);

  if (!currentUser) {
    logger.error(`Could not decode user from token: ${token}`);
    return;
  }

  if (currentUser.branchId && !currentUser.accountNumber) {
    logger.info(`User hasn't finished Online Application`, {currentUser});
    throw new NoOpenAccountError(
      'No Account Number Associated with this user',
      {
        currentUser,
        name: 'NoOpenAccountError',
      },
    );
    return;
  }

  if (needsConsent) {
    authTokenManager.updateConsentsToken(true);
  }

  authTokenManager.updateSessionToken(token);
  useAuthSlice.setState({
    authenticated: true,
    currentUser,
    expired: false,
  });

  analytics.setAuthedContext(currentUser);
  return currentUser;
};

export const unauthenticate = (expired = false, location) => {
  authTokenManager.updateSessionToken(null);
  useAuthSlice.setState({
    authenticated: false,
    currentUser: null,
    expired,
    expiresAt: null,
  });
  queryClient.clear();
  analytics.clearAuthedContext(expired, location);
};

export const clearConsentNeeded = () => {
  authTokenManager.updateConsentsToken(null);
};

export const clearExpired = () => useAuthSlice.setState({expired: false});

export const updateSessionInfo = ({expiresAt, token}) => {
  if (!useAuthSlice.getState().authenticated) {
    return null;
  }

  authTokenManager.updateSessionToken(token);
  useAuthSlice.setState({expiresAt});
};

export function getRememberMeToken() {
  return authTokenManager.getRememberMeToken();
}

export function attachAuthInterceptors(client) {
  client.interceptors.request.use(request => {
    request.headers.Authorization =
      authTokenManager.getSessionToken() ?? undefined;
    return request;
  });

  client.interceptors.response.use(
    response => {
      if (response?.data?.expiresAt && response?.data?.token) {
        updateSessionInfo({
          expiresAt: response.data.expiresAt,
          token: response.data.token,
        });
      }
      return response;
    },
    error => {
      if (error?.response?.status === 401) {
        unauthenticate();
        window.location.assign(window.location);
        return;
      }
      return Promise.reject(error);
    },
  );
}
