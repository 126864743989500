import axios from 'axios'
import { analytics, logger } from './analytics'

export let axiosClient

export function createHttpClient(localURL) {
    const currentHost = window.location.hostname
    const client = axios.create({
        baseURL: currentHost.includes(localURL) ? '' : `https://${currentHost}`,
        headers: {
            'Content-Type': 'application/json'
        }
    })
    
    axiosClient = client
    return client
}