import React from 'react'
import { Icon, Image } from '@chakra-ui/react'

import { environment } from 'core'

import { ReactComponent as LockSVG } from 'assets/img/password-icon.svg'
import { ReactComponent as BankSVG } from 'assets/img/bank-icon.svg'
import { ReactComponent as DebitCardSVG } from 'assets/img/debit-card-icon.svg'
import { ReactComponent as EditSVG } from 'assets/img/edit-icon.svg'
import { ReactComponent as NotificationSVG } from 'assets/img/notification.svg'
import { ReactComponent as TrashSVG } from 'assets/img/trash-icon.svg'

export function CheckMarkIcon(props) {
    const imageSrc = `${process.env.PUBLIC_URL}/Content/assets/img/checkmark-icon.${environment.supportsWebP ? 'webp' : 'png'}`

    return (
        <Image 
            aria-hidden="true" 
            boxSize="2.075rem" 
            mr="0.5rem" 
            src={imageSrc} 
            {...props} 
        />
    )
}

export const BankIcon = props => <Icon as={BankSVG} {...props} />
export const DebitCardIcon = props => <Icon as ={DebitCardSVG} {...props} />
export const EditIcon = props => <Icon as={EditSVG} {...props} />
export const LockIcon = props => <Icon as={LockSVG} {...props} />
export const NotificationIcon = props => <Icon as={NotificationSVG} {...props} />
export const TrashIcon = props => <Icon as={TrashSVG} {...props} />
