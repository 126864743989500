import { QueryClient } from 'react-query'

import { mutationCache, queryCache } from './caches'
import { defaultClientOptions } from './configuration'

export let queryClient = new QueryClient({
    defaultOptions: defaultClientOptions,
    mutationCache,
    queryCache
})

export function updateQueryClient(updatedOptions = {}) {
    queryClient.setDefaultOptions(updatedOptions)
}

