const brand = {
  blue: "#0f3d59",
  darkGrey: "#5a5b5c",
  footer: "#00182f",
  grey: "#696969",
  lightGrey: "rgb(220, 219, 219)",
  lightBlue: "#0876BF",
  mediumBlue: "#2371a5",
  red: "#b61f38",
};

const states = {
  activeLight: "#e3f2fb",
  activeMedium: "#215982",
  activeDark: brand.blue,
  disabled: "#d2d4d6",
  error: "#ed0c31",
  transparentMedium: "rgba(35, 113, 165, 0.3)",
  selected: "#e1f1fc",
};

export const colors = {
  brand,
  states,
  white: "#ffffff",
};
