export function getElementSelector(node, maxLength = 100) {
    let selector = ''

    try {
        while (node && node.nodeType !== 9) {
            const part = node.id ? `#${node.id}` : node.nodeName.toLowerCase() + getNodeClasses(node)
            if (selector.length + part.length > maxLength - 1) {
                return selector || part
            }

            selector = selector ? `${part}>${selector}` : part

            if (node.id) {
                break
            }

            node = node.parentNode
        }
    } catch (error) {}

    return selector
}

export function getNodeClasses(node) {
    return node && node.className?.length
        ? `.` + Array.from(node.classList.values()).join('.')
        : '' 
}