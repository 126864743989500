import { exists } from './types'

/**
 * This function helps to mask sensitive data. Each token in the MASK_TOKENS map is an object with a limit, the number of characters to display, and a mask to use for the 
 * masking character. If the token is not found then the normal, unmasked value is returned.
 */

const MASK_TOKENS = {
    fundingAccount: {
        limit: 4,
        mask: '*'
    },
    tokenId: {
        limit: 4,
        mask: '*'
    }
}

function masker(key, value) {
    if (!MASK_TOKENS[key]) {
        return value
    }

    if (!exists(value) || value === '') {
        return ''
    }
    
    const stringifiedValue = String(value)
    const { limit = 0, mask } = MASK_TOKENS[key]

    if (limit < 0) {
        throw new Error(`Masker expected limit to be a positive integer. Received: ${limit} instead`)
    }
    
    // No Chars Allowed. Mask All
    if (limit === 0) {
        return mask.repeat(stringifiedValue.length)
    }

    const diff = stringifiedValue.length - limit
    if (diff > 0) {
        return mask.repeat(diff) + stringifiedValue.slice(-limit)            
    }

    // Diff is <= 0 mask all.
    return mask.repeat(stringifiedValue.length)
}

export function maskData(data) {
    if (!exists(data)) {
        return ''
    }

    return JSON.stringify(data, masker, 2)
}