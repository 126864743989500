import React from 'react'

export class formatHelper {
  static formatMoney(cell) {
    if (cell < 0) {
      return (
        <span style={{color: 'red'}}>
          $ {cell.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
        </span>
      )
    } else {
      return '$' + cell.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
    }
  }
}

export function formatDate(passedDate) {
  if (!passedDate) return ''

  const date =
    Object.prototype.toString.call(passedDate) === '[object Date]'
      ? passedDate
      : new Date(passedDate)
  const yyyy = date.getFullYear()

  let dd = date.getDate()
  if (dd < 10) {
    dd = `0${dd}`
  }

  let mm = date.getMonth() + 1
  if (mm < 10) {
    mm = `0${mm}`
  }

  return `${mm}/${dd}/${yyyy}`
}

export function formatMoney(value) {
  if (!value) return ''

  const parsed = Number(value)

  return `$${parsed.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`
}

export function formatJsonLink(value) {
  if (!value) return ''

  return value.replace(/^"(.*)"$/, '$1')
}

export function formatPhone(value) {
  if (!value) return ''

  return value.replace(/[^\d]/g, '')
}
