import React from 'react'
import { Button, IconButton } from '@chakra-ui/react'

export function GhostButton({ children, hoverColor, iconButton, ...rest }) {
    const Element = iconButton ? IconButton : Button

    return (
        <Element
            alignItems="center"
            display="flex"
            justifyContent="flex-start"
            variant="ghost"
            _active={{
                background: hoverColor
            }}
            _focus={{
                boxShadow: 'none'
            }}
            _hover={{
                background: hoverColor,
                _disabled: {
                    background: 'none',
                    opacity: 0.4
                }
            }}
            {...rest}
        >
            {children}
        </Element>
    )
}