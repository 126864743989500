import React from 'react';

import {Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay, VStack} from '@chakra-ui/react';

import {Heading} from 'components/elements';

export function BlankNotificationModal({body, closeButton = true, footer, title = '', handleClose, header}) {
  return (
    <Modal isOpen onClose={handleClose}>
      <ModalOverlay bg="rgba(255, 255, 255, 0.8)" />
      <ModalContent>
        {closeButton && <ModalCloseButton background="none" border="none" />}
        <ModalBody px="40px" py="50px">
          <VStack spacing={6} pt="30px">
            {header && <Heading align="center">{header}</Heading>}
            {title && <Heading align="center">{title}</Heading>}
            <div> {body}</div>

            {footer}
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
