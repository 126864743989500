import React, { useEffect } from 'react'
import {
    Heading,
    Modal,
    ModalBody,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalOverlay,
    HStack,
    Text,
    useDisclosure
} from '@chakra-ui/react'

import { analytics } from 'core'
import { PrimaryButton, SecondaryButton } from 'components/elements'
import { useLogoutMutation, useRefreshMutation, useSessionExpirationAlert } from 'services/auth'


export function SessionExpiringModal() {
    const { isOpen, onOpen, onClose } = useDisclosure()
    useSessionExpirationAlert(onOpen)

    const { mutate: logout } = useLogoutMutation('SessionExpirationModal')
    const { isLoading, mutateAsync: refresh } = useRefreshMutation(onClose)

    useEffect(() => {
        let timeout

        if (isOpen) {
            analytics.event('user/modalView', {
                type: 'SessionExpirationModal'
            })

            timeout = setTimeout(() => {
                onClose()
                logout(true)
            }, 60000)
        }

        return () => {
            timeout && clearTimeout(timeout)
        }
    }, [isOpen])

    return (
        <Modal
            autoFocus={false}
            closeOnEsc={false}
            closeOnOverlayClick={false}
            isCentered
            isOpen={isOpen}
            onClose={onClose}
        >
            <ModalOverlay bg="rgba(255, 255, 255, 0.8)" />
            <ModalContent 
                direction="column"
                display="flex"
                justifyContent="center"
                maxW="550px"
                my={0}
                textAlign="center"
            >
                <ModalHeader>
                    <Heading color="brand.blue">Are you still there?</Heading>
                </ModalHeader>
                <ModalBody>
                    <Text>Your session is about to expire due to inactivity. Do you want to extend the session?</Text>
                </ModalBody>
                <ModalFooter>
                    <HStack align="center" direction="row" spacing={3} w="100%">
                        <PrimaryButton flex="1" isLoading={isLoading} onClick={refresh}>Continue Session</PrimaryButton>
                        <SecondaryButton flex="1" onClick={logout}>Sign Out</SecondaryButton>
                    </HStack>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}