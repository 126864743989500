import { EventManager } from '../../internal'
import { observeTimings, TimingTypeMap } from './timings'
import { observeWebVitals } from './web-vitals'

const types = Object.keys(TimingTypeMap).map(type => type.toLowerCase())

const initialPerformanceBuffer = {
    longtask: [],
    marks: [],
    measure: [],
    resource: []    
}
export function performancePlugin(instance) {
    let isFirstLoad = true
    let observer
    
    let performanceBuffer = { ...initialPerformanceBuffer }

    function start() {
        if (window['PerformanceObserver'] && PerformanceObserver.supportedEntryTypes) {
            if (document.readyState !== 'complete') {
                EventManager.bind(window, 'load', setTimeout(() => observe(), 0))
            } else {
                observe()
            }
        }
    }

    function refresh() {
        if (observer) {
            observer.disconnect()
            observer = null
        }

        start()
    }

    function flush() {
        const state = instance.getState()
        instance.event('measure/pagePerformance', {
            properties: {
                previousScreenContext: state.previousScreenContext,
                screenContext: state.currentScreenContext,
                ...performanceBuffer
            }            
        })
        performanceBuffer = { ...initialPerformanceBuffer }
    }

    function observe() {
        if (isFirstLoad) {
            observeWebVitals(instance.measure)
            isFirstLoad = false
        }

        try {
            if (observer) {
                observer.disconnect()
            }

            observer = new PerformanceObserver(handleObserverCallback)
            for (const type of types) {
                if (PerformanceObserver.supportedEntryTypes.indexOf(type) >= 0) {
                    observer.observe({ buffered: true, type })
                }                
            }
        } catch (error) {
            instance.trace(`Failed to execute observe in performance plugin`, { error })            
        }
    }

    function handleObserverCallback(entries) {
        for (const { data, type } of observeTimings(entries)) {
            if (type === TimingTypeMap.Navigation) {
                instance.event('measure/navigationPerformance', data)
            } else if (performanceBuffer[type]) {
                performanceBuffer[type].push(data)
            }
        }
    }

    return { refresh, flush }
}