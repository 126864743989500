import React, { forwardRef } from 'react'

import { NumberField } from './NumberField'

const defaultCurrencyProps = {
    allowNegative: false,
    decimalScale: 2,
    fixedDecimalScale: true,
    placeholder: '$0.00',
    prefix: '$',
    thousandSeparator: true    
}

function BaseCurrentField(props, ref) {
    return <NumberField {...defaultCurrencyProps} {...props} ref={ref} />
}

export const CurrencyField = forwardRef(BaseCurrentField)