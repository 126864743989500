import { useEffect } from 'react'

import { useCallbackRef } from './useCallbackRef'

export function useTimeout(callback, delay, enabled = true) {
    const callbackFn = useCallbackRef(callback)

    useEffect(() => {
        if (!delay || !enabled) return undefined

        let timeoutID = setTimeout(() => {
            callbackFn()
        }, delay)

        return () => {
            if (timeoutID) {
                clearTimeout(timeoutID)
            }
        }
    }, [callbackFn, delay, enabled])
}