import React from 'react'
import { PrimaryButton } from 'components/elements'

export function RedirectToDebitButton({
    isLoading,
    redirectToDebit
}) {
    return (
        <PrimaryButton
            fontSize={{ base: '12px', md: '14px' }}
            isLoading={isLoading}
            size="md"
            onClick={redirectToDebit}
            width="auto"
        >
            Pay with Debit Card
        </PrimaryButton>
    )
}