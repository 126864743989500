export const TimingTypeMap = {
    LongTask: 'longtask',
    Mark: 'mark',
    Measure: 'measure',
    Navigation: 'navigation',
    Resource: 'resource'
}

export function observeTimings(entries) {
    return entries
        .getEntries()
        .map(process)
        .filter(Boolean)
}

function process(entry) {
    let target

    switch (entry.entryType) {
        case TimingTypeMap.Navigation:
            target = processNavigation(entry)
            break
        case TimingTypeMap.Resource:
            target = processResource(entry)
            break
        case TimingTypeMap.Mark:
        case TimingTypeMap.Measure:
            target = processUserTiming(entry)
            break
        case TimingTypeMap.LongTask:
            target = processLongTask(entry)
            break
        default:
            break
    }

    if (!target) return null

    return {
        data: target,
        type: entry.entryType,
    }
}

function processNavigation(entry) {
    return {
        properties: {
            navigationType: entry.type,
            ...getNetworkDefaultsForResource(entry)
        }
    }
}

function processResource(entry) {
    // Don't measure and track the navigation beacon app insights uses to send this
    // telemetry
    if (/(v2\/track)/.test(entry.name)) {
        return false
    }

    // Format this URL as it is entirely too long.
    let url
    try {
        url = new URL(entry.name)
    } catch (error) {
        return false
    }

    let resourceName = url.pathname
    if (url.hostname === window.location.hostname) {
        resourceName.replace('/apps/accounts', '')    
    }

    return {
        entryType: entry.entryType,
        initiatorType: entry.initiatorType,
        is304: is304NotModified(entry),
        isCacheHit: isCacheHit(entry),
        isCompressed: entry.encodedBodySize > 0 && entry.decodedBodySize > 0 && entry.encodedBodySize !== entry.decodedBodySize,
        resourceName,
        workerProcessingTime: entry.workerStart && entry.fetchStart
            ? entry.fetchStart - entry.workStart
            : null        
    }
}

function processUserTiming(entry) {
    if (/Warning/.test(entry.name)) {
        return { markType: 'ReactPerformanceWarning', ...entry }
    }
}

function processLongTask(entry) {
    return entry
}

function isCacheHit(entry) {
    // If bytes were transferred then it wasn't a cache hit, unless the resource has
    // a cache mode of validated, then it will return 300 exactly. 
    // (Exception: This returns false for 304 not modified) 
    if (entry.transferSize > 0 && entry.transferSize !== 300) {
        return false
    }

    // If the body is non-zero then we are in a ResourceTiming2 browser, this was 
    // the same origin or TAO, and the transfer was 0. This was in the cache
    if (entry.decodedBodySize > 0) return true

    // Fallback to a duration checking heuristic (non-RT2 or cross-origin request)
    return entry.duration < 30
}

function is304NotModified(entry) {
    // Conditionally fetched resources with an If-Modified-Since or Etag header might return a 304.
    // in this case, the transfer size might be small because it reflects the 304 response and no content body.
    // Transfer size might be less than the encoded body size in this case. EncodedBodySize and DecodedBodySize should be
    // the size of the previously cached resource
    if (entry.encodedBodySize > 0 && entry.transferSize > 0 && entry.transferSize < entry.encodedBodySize) {
        return true
    }
    // unknown
    return null
}

function getBlockingTime(entry) {
    let blockingTime = 0
    if (entry.connectEnd && entry.connectEnd === entry.fetchStart) {
        blockingTime = entry.requestStart - entry.connectEnd
    } else if (entry.domainLookupStart) {
        blockingTime = entry.domainLookupStart - entry.fetchStart
    }
    return blockingTime.toFixed(2)
}

// Round these values so we don't have insane amounts of percision eating up our
// event payload size
function getNetworkDefaultsForResource(entry) {
    const dnsLookup = (entry.domainLookupEnd - entry.fetchStart) - (entry.domainLookupStart - entry.fetchStart)
    const serverLatency = Math.round(entry.connectEnd - entry.fetchStart) - (entry.connectStart - entry.fetchStart)
    const sslHandshake = entry.secureConnectionStart !== 0 
        ? (entry.secureConnectionStart - entry.connectStart) - entry.fetchStart
        : 0
    const serverComputeTime = (entry.responseStart - sslHandshake - serverLatency - dnsLookup)

    return {
        blockingTime: getBlockingTime(entry),
        childAssets: (((entry.domComplete - entry.fetchStart) ?? entry.duration) - ((entry.domContentLoadedEventEnd - entry.fetchStart) > (entry.responseEnd - entry.fetchStart)
            ? (entry.domContentLoadedEventEnd - entry.fetchStart)
            : (entry.responseEnd - entry.fetchStart)).toFixed(2)
        ),
        dnsLookup,
        decodedSize: entry.decodedBodySize,
        encodedSize: entry.encodedBodySize,
        renderTime: entry.domContentLoadedEventEnd - entry.responseEnd,
        serverLatency,
        sslHandshake,
        serverComputeTime,
        transferTime: entry.responseEnd - entry.responseStart
    }
}