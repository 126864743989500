import { connectionManager } from './connection-manager'
import { onlineManager } from './online-manager'
import { supports } from './supports'

class Environment {
    constructor() {
        this.connectionManager = connectionManager
        this.deviceProperties = undefined
        this.deviceToken = undefined
        this.onlineManager = onlineManager
        this.supports = supports
    }
}

export const environment = new Environment()