import React from 'react'
import { useForm } from 'react-hook-form'

import { Field, Form, SubmitButton } from 'components/elements'
import { PasswordRequirements } from 'components'
import { useBreakpointEffect } from 'hooks'
import { useResetPasswordMutation } from 'services/auth'

const formConfig = {
    defaultValues: { confirmPassword: '', newPassword: '' },
    mode: 'onChange'
}

export function ResetForm({ recoveryToken }) {
    const { data, isLoading, mutate, reset } = useResetPasswordMutation({ recoveryToken })
    const { formState: { errors, isDirty, isValid }, getValues, handleSubmit, register, setFocus, watch } = useForm(formConfig)
    
    useBreakpointEffect('lg', () => {
        setFocus('newPassword')
    })

    const newPassword = watch('newPassword')
    const isSubmitDisabled = !isDirty || !isValid || !(/^(?=.{10,})(?=.*[a-z])(?=.*[A-Z])(?=.*[^\w])(?=.*[0-9_]).*$/).test(newPassword)

    return (
        <Form onSubmit={handleSubmit(mutate)} margin="0 auto" maxW="400px" w="100%">
            <PasswordRequirements currentPassword={newPassword} />
            <Form.Error message={data} mb="1rem" />
            <Field 
                error={errors.newPassword}
                label="New Password"
                onFocus={reset} 
                type="password"
                {...register("newPassword", {
                    required: "Please enter a new password"
                })}
            />
            <Field
                error={errors.confirmPassword}
                label="Confirm Password"
                onFocus={reset}
                type="password"
                {...register("confirmPassword", {
                    required: "Please confirm your password",
                    validate: {
                        matchesPassword: value => getValues().newPassword === value || "Your passwords do not match"
                    }
                })}
            />
            <SubmitButton isDisabled={isSubmitDisabled} isLoading={isLoading}>
                Reset Password
            </SubmitButton>
        </Form>             
    )
}