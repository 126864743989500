import { noop } from './helpers'

export class Subscribable {
    constructor() {
        this.listeners = []

        this.subscribe = this.subscribe.bind(this)
        this.notify = this.notify.bind(this)
    }

    hasListeners() {
        return this.listeners.length > 0
    }

    subscribe(listener = noop) {
        this.listeners.push(listener)
        this.onSubscribe()

        return () => {
            this.listeners = this.listeners.filter(x => x !== listener)
            this.onUnsubscribe()
        }
    }

    notify(notificationHandler) {
        if (this.hasListeners()) {
            for (const listener of this.listeners) {
                notificationHandler(listener)
            }
        }
    } 

    onSubscribe() {}
    onUnsubscribe() {}
}