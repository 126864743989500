import decodeJWT from 'jwt-decode'

import { omit } from './helpers'

function attemptTokenParse(token, parseFn) {
    let parsed = null

    try {
        parsed = parseFn(token)
    } catch (error) {}

    return parsed
}

export function createTokenManager({ needsConsent, rememberMe, session }) {
    const { key: needsConsentKey, storage: needsConsentStorage } = needsConsent
    const { key: rememberMeKey, storage: rememberMeStorage } = rememberMe
    const { key: sessionTokenKey, storage: sessionTokenStorage } = session

    function decodeToken(passedToken) {
        return omit(
            attemptTokenParse(passedToken, decodeJWT),
            ['exp', 'iat', 'nbf']
        )
    }
    
    function getConsentsToken() {
        return attemptTokenParse(
            needsConsentStorage.getItem(needsConsentKey),
            JSON.parse
        )
    }

    function updateConsentsToken(token) {
        if (token) {
            return needsConsentStorage.setItem(needsConsentKey, JSON.stringify(token))
        }
        return needsConsentStorage.removeItem(needsConsentKey)
    }

    function getSessionToken() {
        return attemptTokenParse(
            sessionTokenStorage.getItem(sessionTokenKey),
            JSON.parse
        )
    }

    function updateSessionToken(token) {
        if (token) {
            return sessionTokenStorage.setItem(sessionTokenKey, JSON.stringify(token))
        }
        return sessionTokenStorage.removeItem(sessionTokenKey)
    }

    function getRememberMeToken() {
        return rememberMeStorage.getItem(rememberMeKey)
    }

    function updateRemberMeToken(hasToken, username) {
        if (hasToken) {
            return rememberMeStorage.setItem(rememberMeKey, username)
        }
        return rememberMeStorage.removeItem(rememberMeKey)
    }
    
    return {
        decodeToken,
        getConsentsToken,
        updateConsentsToken,
        getSessionToken,
        updateSessionToken,
        getRememberMeToken,
        updateRemberMeToken
    }
}