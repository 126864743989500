import React, {
    createContext,
    useContext,
    useEffect,
    useMemo,
    useState
} from 'react'

const ConfigContext = createContext(null)

export function ConfigProvider(props) {
    const { children, config } = props
    const contextValue = useMemo(() => config, [config])

    return (
        <ConfigContext.Provider value={contextValue}>
            {children}
        </ConfigContext.Provider>
    )
}

export function useAppConfig() {
    return useContext(ConfigContext)
}