import React from 'react'
import { Heading } from '@chakra-ui/react'

const headingStyles = {
    color: 'brand.blue',
    fontSize: { base: '1.85rem', lg: '4xl' },
    my: { base: '2rem', lg: '3rem', xl: '2.5rem' }
}

export function ErrorHeading({ children, ...rest }) {
    return (
        <Heading {...headingStyles} {...rest}>{children}</Heading>
    )
}