import React from "react";
import { Box, List, ListIcon, ListItem } from "@chakra-ui/react";
import { CheckIcon } from "@chakra-ui/icons";

import { InfoPanel } from "../elements";
import { defaultValidators } from "./defaultValidators";

export function PasswordRequirements({
  newPassword = "",
  validators = defaultValidators,
  ...rest
}) {
  return (
    <Box w="100%" {...rest}>
      <InfoPanel mb={4} pt="10px" id="paswordRequirements">
        <List fontSize="12px" pl="5px" mb="0">
          {validators.length > 0 &&
            validators.map(({ label, name, validate }) => (
              <ListItem key={name}>
                {validate(newPassword) && (
                  <ListIcon as={CheckIcon} color="green.500" />
                )}
                {label}
              </ListItem>
            ))}
        </List>
      </InfoPanel>
    </Box>
  );
}