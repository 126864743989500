import React, { useMemo } from 'react'

import { useLogoutMutation } from 'services/auth'
import { HeaderDropdown } from './HeaderDropdown'
import { MobileMenu } from './MobileMenu'

const useMenuLinks = () => {
    const { mutate: logout } = useLogoutMutation('NavigationMenu')
    return useMemo(() => ([
        { path: '/details', text: 'Account Details' },
        { path: '/change-password', text: 'Change password' },
        { onClick: logout, text: 'Logout' }
    ]), [logout])
}

export function HeaderMenu({ isNotMobile }) {
    const links = useMenuLinks()
    return isNotMobile ? <HeaderDropdown links={links} /> : <MobileMenu links={links} />
}