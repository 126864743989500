import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useUnlockAccountMutation } from 'services/auth/mutations'


import './index.css'

export function UnlockAccount({ history, location }) {
    const { token } = useParams()
    const { data, isLoading, mutate, reset } = useUnlockAccountMutation({ token,  onSuccess:  (data) => {
        history.push({pathname: '/login', state: { unlockAccountSuccess: data }})
    }  });

    useEffect(() => {
           const result = mutate({token});
    }, [location.state, token])

    return (<div>Loading...</div>
    )
}
