import create from 'zustand'
import { initializeSocketConnection } from './initializeSocketConnection'
import { loadConfig } from './loadConfig'

export const [useFeatureSelector, configStore] = create((set, get) => ({}))

export async function initializeConfig(configURL) {
    const { config, features } = await loadConfig(configURL)
    const connection = await initializeSocketConnection(config.configServicingEndpoint)
    
    configStore.setState(features)
    
    if (connection) {
        connection.on('configurationUpdate', data => {
            configStore.setState({ [data.value.id]: data.value })            
        })        
    }

    return { config, features }
}


