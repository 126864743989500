import React from 'react'
import { Modal } from 'react-bootstrap'

import { LoadingSpinner } from 'components/elements'
import { usePaymentProfile } from 'services/payment'

import { DebitOnlyPayment } from './DebitOnlyPayment'
import { StandardPaymentSelection } from './StandardPaymentSelection'

export function PaymentTypeSelectionModal({
    contractNumber,
    handleClose,
    oneTimeACHAllowed,
    redirectToDebit
}) {

    const { data: paymentProfile, isLoading } = usePaymentProfile(contractNumber)

    const handleRedirect = () => {
        redirectToDebit(paymentProfile)
    }

    const ComponentToRender = oneTimeACHAllowed !== 'N'
        ? StandardPaymentSelection
        : DebitOnlyPayment
    
    return (
        <Modal show onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title align="center">Select Payment Type</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div align="center" className="row">
                    {isLoading ? <LoadingSpinner size="45px" /> : <ComponentToRender redirectToDebit={handleRedirect} />}
                </div>
            </Modal.Body>
        </Modal>
    )
}