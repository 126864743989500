import React from 'react'
import {useForm} from 'react-hook-form'
import {useState} from 'react'
import {Field, Form, SubmitButton} from 'components/elements'
import {useBreakpointEffect} from 'hooks'

export function RequestResetForm({
  error,
  isLoading,
  requestReset,
  resetMutationState,
}) {
  const {
    formState: {errors, isDirty, isValid},
    handleSubmit,
    register,
    setFocus,
  } = useForm({
    mode: 'onChange',
  })

  useBreakpointEffect('lg', () => {
    setFocus('username')
  })

  const [passwordType, setPasswordType] = useState('password')
  const [passwordInput, setPasswordInput] = useState('')
  const handlePasswordChange = evnt => {
    setPasswordInput(evnt.target.value)
  }

  const [passwordShown, setPasswordShown] = useState(false)

  const togglePassword = () => {
    setPasswordShown(!passwordShown)
    if (passwordType === 'password') {
      setPasswordType('text')
      return
    }
    setPasswordType('password')
  }

  const isSubmitDisabled = !isDirty || !isValid

  return (
    <Form
      onSubmit={handleSubmit(requestReset)}
      margin="0 auto"
      maxW="350px"
      pt="1.25rem"
    >
      <Form.Error message={error?.message} />

      <Field
        error={errors.username}
        id="username"
        label="Email or Username"
        type="text"
        onFocus={() => {
          if (error) {
            resetMutationState()
          }
        }}
        {...register('username', {
          required: 'Please enter your email or username',
        })}
      />

      <SubmitButton
        isDisabled={isSubmitDisabled}
        isLoading={isLoading}
        mt="1rem"
      >
        Continue
      </SubmitButton>
    </Form>
  )
}
