import { useCallback, useMemo } from 'react'
import { useTimeout } from 'hooks'

import { clearExpired, useAuthSlice } from './core'

export function useIsAuthenticated() {
    return useAuthSlice(useCallback(state => state.authenticated, []))
}

export function useCurrentUser() {
    return useAuthSlice(useCallback(
        state => state.currentUser,
        []
    ))
}

export function useDidSessionExpire() {
    const isExpired = useAuthSlice(useCallback(state => state.expired, []))
    useTimeout(clearExpired, 5000, !!isExpired)
}

export function useSessionExpirationAlert(onExpiration) {
    const isAuthenticated = useIsAuthenticated()
    const expiresAt = useAuthSlice(useCallback(state => state.expiresAt, []))

    const expiresIn = useMemo(() => {
        return new Date(expiresAt).getTime() - Date.now() - 120000
    }, [expiresAt])

    useTimeout(onExpiration, expiresIn, !!isAuthenticated && expiresAt != null)
}