import React, {useCallback, useEffect} from 'react';
import {useHistory} from 'react-router-dom';

import {logger} from 'core';
import {MODAL_TYPES, useModalState, closeModal} from 'services/ui';

import {
  ACHPaymentModal,
  EConsentModal,
  NotificationModal,
  BlankNotificationModal,
  PaymentTypeSelectionModal,
  RedirectToACIModal,
  ACIModal,
  EDeliveryConsentModal,
} from './components';

const componentPathMap = {
  [MODAL_TYPES.ACHPayment]: ACHPaymentModal,
  [MODAL_TYPES.EConsentModal]: EConsentModal,
  [MODAL_TYPES.NotificationModal]: NotificationModal,
  [MODAL_TYPES.BlankNotificationModal]: BlankNotificationModal,
  [MODAL_TYPES.PaymentTypeSelection]: PaymentTypeSelectionModal,
  [MODAL_TYPES.RedirectToACI]: RedirectToACIModal,
  [MODAL_TYPES.ACIModal]: ACIModal,
  [MODAL_TYPES.EDeliveryConsentModal]: EDeliveryConsentModal,
};

export function ModalRenderer() {
  const history = useHistory();
  const {componentProps, path} = useModalState();

  useEffect(() => {
    const unsubscribe = history.listen(location => {
      closeModal();
    });

    return () => {
      closeModal();
      unsubscribe();
    };
  }, []);

  const handleClose = useCallback(() => {
    if (typeof componentProps?.beforeClose === 'function') {
      componentProps.beforeClose();
    }

    closeModal();

    if (typeof componentProps?.onClose === 'function') {
      componentProps.onClose();
    }
  }, [componentProps]);

  useEffect(() => {
    if (path) {
      logger.info(`User opened modal ${path}`);
    }
  }, [path]);

  if (path == null) return null;

  const Component = componentPathMap[path];
  return <Component {...componentProps} handleClose={handleClose} />;
}
