import React from 'react'
import { Modal } from 'react-bootstrap'

import { useEditDebitMutation } from 'services/payment'

export function RedirectToACIModal({
    billingAccountNumber,
    handleClose,
    profileId,
    tokenId
}) {
    const { isLoading, mutate: executeFundingRedirect } = useEditDebitMutation({
        billingAccountNumber,
        profileId,
        tokenId
    })

    return (
        <Modal show onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title align="center">You are leaving the Republic Finance website</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row" align="center">
                    <div className="col-xs-12 col-md-12">
                    Our trusted partner, ACI Payments, will verify your debit card information, and then
                    you’ll be all set to make payments to Republic Finance with your card. You’ll
                    automatically be returned to the Republic Finance website when finished.
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-danger" style={{ color: '#fff' }} onClick={handleClose}>
                    Cancel
                </button>
                <button
                    className="btn btn-success"
                    disabled={isLoading}
                    style={{ color: '#fff' }}
                    onClick={executeFundingRedirect}
                >
                    Continue
                </button>
            </Modal.Footer>
      </Modal>        
    )
}

