import { queryClient } from 'core'
import { useQuery, useQueries } from 'react-query'

import * as accountApi from './api'
import { getContractSchedule } from 'services/payment'
import { ConsoleLogger } from '@microsoft/signalr/dist/esm/Utils'

const queryKeys = {
  branch: (branchId) => ['branch', branchId],
  contracts: () => ['contracts'],
  contract: (billingAccountNumber) => ['contract', billingAccountNumber],
  transactions: (contractNumber) => ['transactions', contractNumber],
}

export function contractsQuery() {
  return {
    queryFn: async () => {
      const response = await accountApi.getContracts()
      return response.map((contract) => {
        const { accountNumber, branchid, contractNumber } = contract.request
        const billingAccountNumber = `${branchid}-${accountNumber}-${contractNumber}`
        const updatedContract = { ...contract, billingAccountNumber }

        if (
          contract.debitConvenienceFee.isApplicable &&
          contract.debitConvenienceFee.convenienceFeeAmount > 0
        ) {
          updatedContract.convenienceFee =
            contract.debitConvenienceFee.convenienceFeeAmount
        }

        if (
          (contract.eligibility.oneTimeDebitAllowed == 'Y' ||
            contract.eligibility.oneTimeACHAllowed == 'Y') &&
          contract.eligibility.recurringDebitAllowed == 'Y'
        ) {
          updatedContract.schedule = {}
        }

        queryClient.setQueryData(
          queryKeys.contract(billingAccountNumber),
          updatedContract,
        )
        return updatedContract
      })
    },
    queryKey: queryKeys.contracts(),
  }
}

export async function initialAccountQuery(previousToken) {
  if (!previousToken) {
    return null
  }

  try {
    const contracts = await queryClient.fetchQuery({
      ...contractsQuery(),
      refetchOnWindowFocus: false,
    })

    if (contracts) {
      return true
    }
    return false
  } catch (error) {
    return false
  }
}

export function useBranchQuery(branchId, options = {}) {
  return useQuery({
    queryFn: () => accountApi.getBranch(branchId),
    queryKey: queryKeys.branch(branchId),
    ...options,
  })
}

export function scheduleQuery(contracts) {
  let autoPayAllowedContracts =
    contracts?.filter(
      (c) =>
        (c.eligibility.oneTimeDebitAllowed == 'Y' ||
          c.eligibility.oneTimeACHAllowed == 'Y') &&
        c.eligibility.recurringDebitAllowed == 'Y',
    ) ?? []

  const contractSchedules = useQueries(
    autoPayAllowedContracts?.map((contract) => {
      return {
        queryKey: [contract.request.contractNumber],
        queryFn: async () => getContractSchedule(contract),
      }
    }),
  )

  return contractSchedules
}

export function useAccountDetailsQuery() {
  const contractsPayload = useQuery(contractsQuery())
  const contracts = contractsPayload?.data
  const schedules = scheduleQuery(contracts)
  const isLoading = schedules?.some((result) => result.isLoading)

  let contractBillingAccounts = {}
  //Wait for all schedules to be fetched
  if (!isLoading) {
    schedules.forEach(function (schedule) {
      contractBillingAccounts[schedule.data?.billingAccountNumber] = schedule
    })
    let updatedContracts = contracts?.map(function (contract) {
      let schedule = contractBillingAccounts[contract.billingAccountNumber]
      if (schedule) {
        for (let key in schedule.data.payload) {
          contract.schedule[key] = schedule.data.payload[key]
        }
      }
      return contract
    })
    //Update Contracts Payload with latest contracts with schedules
    contractsPayload.data = updatedContracts
    return contractsPayload
  }
  return isLoading
}

export function useContractsQuery() {
  return useQuery(contractsQuery())
}

export function useTransactionsQuery(
  contractNumber,
  accountNumber,
  branchid,
  options = {},
) {
  return useQuery({
    queryFn: () =>
      accountApi.getPaymentHistory(contractNumber, accountNumber, branchid),
    queryKey: queryKeys.transactions(contractNumber),
    ...options,
  })
}

export function useGetConsentsQuery() {
  return useQuery({
    queryFn: () => accountApi.getConsent(),
    queryKey: 'consents',
  })
}
