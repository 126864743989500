import { extendTheme } from '@chakra-ui/react'
import { createBreakpoints } from '@chakra-ui/theme-tools'

import { colors } from './colors'
import { components } from './components'

const breakpoints = createBreakpoints({
    sm: "380px",
    lgPhone: "450px",
    md: "820px",
    lg: "1024px",
    xl: "1280px"    
})

export const globalTheme = extendTheme({
    breakpoints,
    colors,
    components,
    fonts: {
        body: "museo-sans",
        heading: "museo-sans",
        promotional: "p22-sneaky-pro"        
    },
    textStyles: {
        "banner-heading": {
            fontFamily: 'heading',
            fontSize: { lg: '2.5rem', xl: '3rem' },
            fontWeight: 'bold'
        },
        "error-heading": {
            fontSize: { base: '1.5rem', lg: '2.25rem' },
            fontWeight: 500,
            margin: { base: '1rem 0', lg: '2.5rem 0' }
        },
        "form-text": {
            color: "brand.darkGrey",
            fontSize: "lg",
            lineHeight: 2.5,
            marginBottom: ".75rem"
        },
        "input-label": {
            color: "brand.darkGrey",
            marginBottom: 1
        },
        "support-text": {
            fontSize: { base: '.85rem', sm: '1rem' }
        }
    },
    styles: {
        global: {
            "html": {
                fontSize: "16px"
            },
            "body": {
                fontSize: "inherit"
            },
            "p": {
                color: 'brand.darkGrey'
            },
            "h1": {
                color: 'brand.blue',
                fontFamily: 'heading',
                fontSize: { lg: '2.5rem', xl: '3rem' },
                fontWeight: 'bold'
            },
            ".c-link": {
                color: 'brand.mediumBlue',
                _hover: {
                    color: 'brand.lightBlue',
                    textDecoration: 'underline'
                }
            },
            ".c-promotional-text": {
                color: 'brand.lightBlue',
                fontFamily: 'promotional',
                fontSize: { lg: '3rem', xl: '3.5rem' },
                fontWeight: 'light'
            },
        }
    }
})