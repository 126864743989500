import React from 'react';
import {CheckIcon} from '@chakra-ui/icons';
import {Button, Center, Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay, VStack} from '@chakra-ui/react';

import {Heading} from 'components/elements';

export function NotificationModal({body, closeButton, footer, handleClose, title = ''}) {
  return (
    <Modal isOpen onClose={handleClose}>
      <ModalOverlay bg="rgba(255, 255, 255, 0.8)" />
      <ModalContent>
        {closeButton && <ModalCloseButton />}
        <ModalBody px="40px" py="50px">
          <VStack spacing={6} pt="30px">
            <Center border="3px solid" borderColor="brand.mediumBlue" borderRadius="50%" h="70px" w="70px">
              <CheckIcon color="brand.mediumBlue" boxSize="30px" />
            </Center>
            {title && <Heading align="center">{title}</Heading>}
            {body}

            <Button isFullWidth onClick={handleClose}>
              OK
            </Button>

            {footer}
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
