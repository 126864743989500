import { useEffect, useRef } from 'react'

export function useConditionalEffect(condition, effect) {
    const effectRef = useRef(effect)
    effectRef.current = effect

    useEffect(() => {
        let shouldRun = condition
        if (typeof condition === 'function') {
            shouldRun = condition()
        }

        if (shouldRun) {
            effect()
        }
    }, [condition])
}