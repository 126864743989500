import { axiosClient } from 'core/httpClient'
import { AuthStatus } from './constants'
import { InvalidOriginForLoginError } from './errors'

const parseAuthResponse = ({ expiresAt = null, payload = {}, success = false, token = null } = {}) => ({
    canAuthenticate: expiresAt !== null && token !== null && payload?.status === AuthStatus.Success,
    expiresAt,
    isAccountLocked: payload?.status === AuthStatus.Locked,
    isActive: payload?.status === AuthStatus.Active,
    isRecovering: payload?.status === AuthStatus.Recovering,
    isAccountStaged: payload?.status === AuthStatus.Staged,
    message: payload?.message ?? '',
    needsEConsent: payload?.consentNeeded === true,
    status: payload?.status ?? AuthStatus.Unknown,
    success,
    token,
    ...payload
})

export async function login({ password, username }) {
    try {
        const { data: loginResponse } = await axiosClient.post('/auth/login', { password, username })
        return parseAuthResponse(loginResponse)
    } catch (error) {
        if (error.response?.data?.status === AuthStatus.InvalidOrigin) {
            throw new InvalidOriginForLoginError("Restrict Access Based on Location", {
                name: 'InvalidOriginForLoginError',
                username
            })
        } else if (error.response?.status === 403) {
            return parseAuthResponse({ payload: {}, success: false })
        }

        throw error
    }
}

export async function changePassword({ currentPassword, newPassword }) {
    const { data: response } = await axiosClient.post('/auth/user/password/change', { currentPassword, newPassword })
    return parseAuthResponse(response)
}

// Potentially track this in storage to see if multiple hits are occuring
export async function forgotPassword({ username }) {
    const response = await axiosClient.post('/auth/login/password/forgot', { username })
    const { payload: { message = '', status = '' }, success } = response.data

    return {
        message,
        requestReceived: success && status === 'RECOVERY_CHALLENGE',
        username
    }
}

export async function resetPassword({ newPassword, recoveryToken }) {
    const { data: response } = await axiosClient.post('/auth/login/password/reset', { newPassword, recoveryToken })
    return parseAuthResponse(response)
}

export async function unlockAccount({ token }) {
    const { data: response } = await axiosClient.post('/auth/login/unlock', { recoveryToken: token })
    return response
}

export async function refreshSession() {
    const { data: response } = await axiosClient.put('/auth/session')
    return parseAuthResponse(response)
}

export async function logout() {
    try {
        await axiosClient.delete('/auth/session')
    } catch (error) {}
}
