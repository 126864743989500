export class CustomError extends Error {
    constructor(message, props) {
        super(message)
        
        this.customProperties = props
        this.isCustom = true
        this.isCritical = props?.isCritical
        this.name = props?.name || this.constructor.name
        
        if (typeof Error.captureStackTrace === 'function') {
            Error.captureStackTrace(this, this.constructor)
        } else {
            this.stack = (new Error(message)).stack
        }
    }
}
