import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { useAppConfig } from 'context'
import { createAuthSubscription, useLogoutMutation } from 'services/auth'

import {
    PrimaryButton,
    SecondaryButton,
    OutboundLink
} from 'components/elements'
import { DefaultErrorTemplate } from './DefaultErrorTemplate'

const buttonStyles = {
    flex: { lg: '1' },
    margin: { base: '.625rem 0', lg: '0 .625rem' },
    w: { base: '100%', lg: 'auto' },
    _hover: {
        color: '#fff',
        textDecoration: 'none'
    },
    _focus: {
        color: '#fff',
        textDecoration: 'none'
    }
}

export function GlobalErrorFallback({ error, resetErrorBoundary }) {
    const { mutate: logout } = useLogoutMutation('GlobalErrorPage')
    const history = useHistory()

    useEffect(() => {
        const unsubscribeHistory = history.listen(location => {
            if (error) {
                resetErrorBoundary()
            }
        })
        return unsubscribeHistory
    }, [error])

    useEffect(() => {
        const unsubscribeAuth = createAuthSubscription((authState, prevAuthState) => {
            if (error && (authState.authenticated !== prevAuthState.authenticated)) {
                resetErrorBoundary()
            }
        })
        return unsubscribeAuth
    }, [error])

    return (
        <DefaultErrorTemplate>
            <PrimaryButton as={OutboundLink} {...buttonStyles} href="contact">Contact Us</PrimaryButton>
            <SecondaryButton {...buttonStyles} onClick={logout}>Back to Sign In</SecondaryButton>
        </DefaultErrorTemplate>
    )  
}