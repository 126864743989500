import React from 'react'
import { Text } from '@chakra-ui/react'

const textStyles = {
    lineHeight: '1.85rem',
    mb: '2rem',
    w: { base: '80%', lg: '70%' }
}

export function ErrorText({ children, ...rest }) {
    return <Text {...textStyles} {...rest}>{children}</Text>
}