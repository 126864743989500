export function createAndInjectDynamicScript({
    async = true,
    attachTo = 'head',
    onLoadCallback = null,
    props,
    src = ''
}) {
    const [hostElement] = document.getElementsByTagName(attachTo)
    const script = document.createElement('script')
    script.async = async

    if (onLoadCallback && typeof onLoadCallback === 'function') {
        script.addEventListener('load', onLoadCallback)
    }

    if (props && typeof props === 'object') {
        const propKeys = Object.keys(props)
        if (propKeys.length > 0) {
            propKeys.forEach(key => {
                if (key.startsWith('data')) {
                    script.setAttribute(key, props[key])
                }
            })
        }
    }
    
    if (props && typeof props === 'object') {
        const propKeys = Object.keys(props)
        if (propKeys.length > 0) {
            propKeys.forEach(key => {
                if (key.startsWith('data')) {
                    script.setAttribute(key, props[key])
                }
            })
        }
    }

    script.src = src
    hostElement.appendChild(script)
}