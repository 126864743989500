const ConnectionStrengths = {
    Weak: 'weak',
    Moderate: 'moderate',
    Strong: 'strong'
}

const ConnectionSpeeds = {
    Offline: 'offline',
    Slow: 'slow',
    Moderate: 'moderate',
    Fast: 'fast',
    VeryFast: 'very-fast',
    GigabitPlus: 'gigabit or greater'
}

const GenericFastSpeedConnections = new Set([
    ConnectionSpeeds.Fast,
    ConnectionSpeeds.VeryFast,
    ConnectionSpeeds.GigabitPlus
])

function getConnectionSpeed({ effectiveConnectionType, maxDownloadSpeed, networkConnectionType }) {
    if (networkConnectionType === 'none' || maxDownloadSpeed === 0) {
        return ConnectionSpeeds.Offline
    }
    if (effectiveConnectionType === 'slow-2g' || effectiveConnectionType === '2g') {
        return ConnectionSpeeds.Slow
    }
    if (effectiveConnectionType === '3g') {
        return ConnectionSpeeds.Moderate
    }
    if (effectiveConnectionType === '4g' && maxDownloadSpeed <= 120) {
        return ConnectionSpeeds.Fast
    }
    if (maxDownloadSpeed > 120 && maxDownloadSpeed < 1000) {
        return ConnectionSpeeds.VeryFast
    }
    if (maxDownloadSpeed >= 1000) {
        return ConnectionSpeeds.GigabitPlus
    }
    return ConnectionSpeeds.Moderate
}

function isFastConnection(connectionSpeed) {
    return GenericFastSpeedConnections.has(connectionSpeed)
}

export function getCurrentConnectionInfo(connection) {
    const connectionSpeed = getConnectionSpeed(connection)
    const connectionStrength = getConnectionStability(connection)
    return {
        connectionSpeed,
        connectionStrength,
        isFastConnection: isFastConnection(connectionSpeed)
    }
}