export const Checkbox = {
    baseStyle: {
        control: {
            _checked: {
                bg: "brand.lightBlue",
                borderColor: "brand.lightBlue",
                color: "white",

                _disabled: {
                    bg: "states.disabled",
                    borderColor: "states.disabled",
                    color: "states.disabled"
                }
            },
            _disabled: {
                bg: "states.disabled",
                borderColor: "states.disabled"
            },
            _invalid: {
                borderColor: "states.error"
            }
        },
        label: {
            bg: 'transparent',
            color: "brand.darkGrey"
        }
    },
    sizes: {
        md: {
            control: { h: 4, w: 4 },
            label: { fontSize: "sm" }
        }
    }
}