import React from 'react';
import {
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalOverlay,
  Text,
  Button,
} from '@chakra-ui/react';

import {formatJsonLink} from '../../../helpers/formatHelper';

function redirect(redirectURL) {
  window.location = formatJsonLink(redirectURL);
}

export function ACIModal({handleClose}) {
  let redirectURL = JSON.parse(localStorage.getItem('aciRedirectUrl'));

  return (
    <Modal
      closeOnEsc={true}
      closeOnOverlayClick={true}
      isOpen
      onClose={handleClose}
      size="2xl"
    >
      <ModalOverlay bg="rgba(255, 255, 255, 0.8)" />
      <ModalContent>
        <ModalHeader color="brand.blue">
          <Heading>You are leaving the Republic Finance website</Heading>
        </ModalHeader>
        <ModalBody>
          <Text>
            Our trusted partner, ACI Payments, will verify your debit card
            information, and then you’ll be all set to make payments to Republic
            Finance with your card. You’ll automatically be returned to the
            Republic Finance website when finished.
          </Text>
        </ModalBody>
        <ModalFooter>
          <Button mr={3} onClick={() => redirect(redirectURL)}>
            Continue
          </Button>
          <Button onClick={handleClose}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
