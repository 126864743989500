import React, { useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useDisclosure,
  Button
} from "@chakra-ui/react";

export function HeaderDropdown({ links }) {
  const buttonRef = useRef(null);
  const menuRef = useRef(null);

  const { onClose, onOpen, isOpen } = useDisclosure();

  const history = useHistory();
  const navigateTo = path => {
    history.push(path);
    onClose();
  };

  useEffect(() => {
    const listener = e => {
      if (
        buttonRef.current?.contains(e.target) ||
        menuRef.current?.contains(e.target)
      ) {
        return;
      }
      onClose();
    };

    if (isOpen) {
      document.addEventListener("mousemove", listener);
    }

    return () => {
      document.removeEventListener("mousemove", listener);
    };
  }, [isOpen]);

  const menuItemStyles = {
    bg: "none",
    border: "none",
    color: "brand.lightBlue",
    _focus: {
      color: "brand.mediumBlue",
      textDecoration: "underline"
    }
  };

  return (
    <Menu gutter={1} isOpen={isOpen} matchWidth={true}>
      <MenuButton
        bg="none"
        border="none"
        borderBottom="4px solid"
        borderColor="transparent"
        borderRadius="0"
        color="brand.blue"
        fontSize="lg"
        fontWeight="semibold"
        height="110px"
        mb="-2px"
        onMouseOver={onOpen}
        ref={buttonRef}
        width="180px"
        _expanded={{ borderColor: "brand.lightBlue" }}
      >
        <span id="manageAccount">Manage Account</span>
      </MenuButton>
      <MenuList
        bg="white"
        boxShadow="0px 3px 5px rgba(0, 0, 0, 0.16)"
        ref={menuRef}
        w="180px"
      >
        {links.map(link => {
          const onClick =
            typeof link.onClick === "function"
              ? link.onClick
              : () => navigateTo(link.path);
          return (
            <MenuItem key={link.text} onClick={onClick} {...menuItemStyles}>
              {link.text}
            </MenuItem>
          );
        })}
      </MenuList>
    </Menu>
  );
}
