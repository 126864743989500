import React from 'react';
import '../../assets/styles/footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <section className="footer-section-container">
        <section className="menu-section mobile-only">
          <ul className="footer-menu-list">
            <li className="footer-menu-item">
              <a target="_blank" href="https://www.republicfinance.com/contact">
                Contact Us
              </a>
            </li>
            <li className="footer-menu-item">
              <a target="_blank" href="https://www.republicfinance.com/payments">
                Payments
              </a>
            </li>
            <li className="footer-menu-item">
              <a target="_blank" href="https://www.republicfinance.com/legal">
                Legal & Privacy
              </a>
            </li>
            <li className="footer-menu-item">
              <a target="_blank" href="https://www.republicfinance.com/disclosure">
                Licenses & Disclosures
              </a>
            </li>
          </ul>
        </section>

        <section className="social-section">
          <a className="logo-container" target="_blank" href="https://www.republicfinance.com">
            <img
              src="https://www.republicfinance.com/RepublicFinance/media/Home/RF-Logo_white.png"
              className="logo"
              alt="Republic Finance"
            />
          </a>
          <p className="copyright">© {new Date().getFullYear()} Republic Finance</p>
          <div className="footer-menu-dt">
            <a target="_blank" href="https://www.republicfinance.com/contact">
              Contact Us
            </a>
            <a target="_blank" href="https://www.republicfinance.com/payments">
              Payments
            </a>
            <a target="_blank" href="https://www.republicfinance.com/legal">
              Legal & Privacy
            </a>
            <a target="_blank" href="https://www.republicfinance.com/disclosure">
              Licenses & Disclosures
            </a>
          </div>
        </section>
      </section>
    </footer>
  );
};
export default Footer;
