import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'

import { FullScreenLoader } from 'components/elements'
import { queryClient } from 'core'
import { initializeProviders } from 'services/initialize-providers'

import { ConfigProvider } from './config'
import { FeaturesProvider } from './features'

export function ServiceProvider(props) {
    const history = useHistory()

    const [initialized, setInitialized] = useState(false)
    const ctxRef = useRef(null)

    useEffect(() => {
        initializeProviders(history).then(passedContext => {
            const { afterInit, config, features } = passedContext
            ctxRef.current = { config, features }

            setInitialized(prev => true)
            afterInit()
        })
    }, [])

    if (!initialized) return <FullScreenLoader />

    const ctx = ctxRef.current
    return (
        <ConfigProvider config={ctx.config}>
            <FeaturesProvider features={ctx.features}>
                <QueryClientProvider client={queryClient}>
                    <ReactQueryDevtools initialIsOpen={false} />
                    {props.children}
                </QueryClientProvider>                  
            </FeaturesProvider>           
        </ConfigProvider>
    )
}