import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import { AuthenticationErrorBoundary } from './components'
import { ForgotPassword } from './forgot-password'
import { Login } from './login'
import { ResetPassword } from './reset-password'
import { UnlockAccount } from './unlock-account'

export function AuthenticationPages() {
    return (
        <AuthenticationErrorBoundary>
            <Switch>
                <Route component={ForgotPassword} exact path="/signin/forgot-password" />
                <Route component={ResetPassword} exact path="/signin/reset-password/:token" />
                <Route component={ResetPassword} exact path="/signin/reset-password" />                                
                <Route component={UnlockAccount} exact path="/signin/unlock/:token" />
                <Route component={UnlockAccount} exact path="/signin/unlock" />
                <Route component={Login} path="/login" />
                <Route path="/mobile/payment" render={(() => {
                    window.location=`republicfinance://payment${window.location.search}`;
                })} />
                <Redirect from="/" to="/login" />
            </Switch>  
        </AuthenticationErrorBoundary>                                                              
    )
}