import React, {forwardRef} from 'react'
import {Input, useMergeRefs} from '@chakra-ui/react'
import {NumericFormat} from 'react-number-format'

function BaseNumberField({name, register, registerConfig, ...rest}, ref) {
  let additionalProps = {}
  if (register && typeof register === 'function') {
    additionalProps = register(name, registerConfig)
  }

  const passedRef = useMergeRefs(ref, additionalProps?.ref)
  return (
    <NumericFormat
      customInput={Input}
      getInputRef={passedRef}
      {...additionalProps}
      {...rest}
    />
  )
}

export const NumberField = forwardRef(BaseNumberField)
