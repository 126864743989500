import { createAndInjectDynamicScript  } from "./createAndInjectDynamicScript"
import { noop } from './helpers'

const hotjarID = 2385738
const hotjarSV = 6

export let hotjarInstance = noop

export function includeHotjar() {
    window.hj = window.hj || function() {
        (window.hj.q = window.hj.q || []).push(arguments)
    }

    window._hjSettings = {
        hjid: hotjarID,
        hjsv: hotjarSV
    }

    createAndInjectDynamicScript({
        src: `https://static.hotjar.com/c/hotjar-${hotjarID}.js?sv${hotjarSV}`
    })


    hotjarInstance = window.hj
    return hotjarInstance
}
