import { UAParser } from "ua-parser-js"

export function collectUserAgent() {
    const { browser, cpu, device, engine, os, ua } = UAParser()
    return {
        browserName: browser.name,
        browserVersion: browser.version,
        cpuArchitecture: cpu.architecture,
        deviceModel: device.model,
        deviceType: device.type,
        deviceVendor: device.vendor,
        engineName: engine.name,
        engineVersion: engine.version,
        osName: os.name,
        osVersion: os.version,
        uaString: ua
    }
}