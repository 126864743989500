import { initializeCore } from 'core'

import { initialAccountQuery } from './account'
import { attachAuthInterceptors, authenticate, authTokenManager } from './auth/core'

export async function initializeProviders(history) {
    const ctx = await initializeCore({
        configURL: process.env.PUBLIC_URL + '/app-config.json',
        globalHistory: history,
    })

    attachAuthInterceptors(ctx.httpClient)

    const authResponse = await initialAccountQuery(authTokenManager.getSessionToken())
    
    if (authResponse) {
        authenticate(authTokenManager.getSessionToken(), authTokenManager.getConsentsToken())
    }
    
    return ctx
}