import React, {useCallback} from 'react';
import {useForm} from 'react-hook-form';
import {useHistory} from 'react-router-dom';
import {
  Box,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalCloseButton,
  Text,
  Button,
} from '@chakra-ui/react';

import {Field, Form, SubmitButton} from 'components/elements';
import {useEDeliveryMutation} from 'services/account';
import {clearConsentNeeded} from 'services/auth';

export function EDeliveryConsentModal({handleClose}) {
  const {
    formState: {errors, isDirty, isValid},
    handleSubmit,
    register,
  } = useForm({
    defaultValues: {
      eDeliveryDisclosure: false,
    },
    mode: 'onChange',
  });

  const {isLoading, mutate: eDeliveryMutation} = useEDeliveryMutation({
    onSuccess: () => {
      clearConsentNeeded();
      handleClose();
    },
  });

  const history = useHistory();
  const handleCancel = useCallback(() => {
    sessionStorage.setItem('reloadContext', true);
    history.push({
      pathname: '/apps/accounts/details',
      state: {},
    });
      history.go();
  }, []);

  const eDeliveryError = errors?.eSignDisclosure;

    return (
      <Modal closeOnEsc={false} closeOnOverlayClick={false} isOpen onClose={handleCancel} size="2xl">
      <ModalOverlay bg="rgba(255, 255, 255, 0.8)" />
      <ModalContent
        alignItems="center"
        direction="column"
        display="flex"
        my={{
          base: '0',
          lgPhone: '3.75rem',
        }}
      >
        <ModalHeader color="brand.blue">
          <Heading size="lg" textAlign="center">
            To set up optional AutoPay, you must provide E-Delivery Consent.
          </Heading>
          <Heading size="sm" textAlign="center">
            If you do not wish to provide E-Delivery Consent to set up AutoPay, you can still make one-time loan
            payments online, over the phone, or at your local Republic Finance Branch.
          </Heading>
        </ModalHeader>
        <ModalCloseButton background="none" border="none" />
        <ModalBody>
          <Form onSubmit={handleSubmit(eDeliveryMutation)}>
            <Flex mb="20px">
              <Box pt="15px" pr="15px">
                <Field
                  field="checkbox"
                  id="eDelivery"
                  isInvalid={eDeliveryError}
                  size="lg"
                  {...register('eDeliveryDisclosure', {
                    required: 'You are required to give this consent',
                  })}
                />
              </Box>
              <Box>
                <Text>
                  By checking this box and clicking "Accept and Continue", you acknowledge your consent for Republic
                  Finance to provide you required disclosures regarding payment transfer attempts by using electronic
                  delivery via email. You are not required to agree to electronic delivery of these federal payment
                  transfer disclosures (Payment Disclosures). You may revoke E-Delivery Consent, and if you revoke
                  E-Delivery Consent then we will not provide Payment Disclosures electronically via email and you may
                  no longer be eligible to set up recurring payments with Republic Finance. We will not deliver Payment
                  Disclosures electronically if we receive notification that you are unable to receive Payment
                  Disclosures through that delivery method at the address used.
                </Text>
              </Box>
            </Flex>
            <SubmitButton isDisabled={!isDirty || !isValid} isLoading={isLoading}>
              Accept and Continue
            </SubmitButton>
            <Button
              variant="outline"
              borderColor="#2371A5"
              color="#2371A5"
              my={2}
              isFullWidth
              type="submit"
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </Form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
