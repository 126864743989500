export async function collectConnectedDevices() {
    const keys = ['hid', 'keyboard', 'media', 'gamepads']
    const data = await Promise.all([
        getHIDDevices(),
        getKeyboardLayout(),
        getMediaDevices(),
        getGamepads()
    ]).catch(error => {})

    return keys.reduce((acc, key, currentIndex) => {
        acc[key] = data[currentIndex]
        return acc
    }, {})
}

function getHIDDevices() {
    if (!('hid' in navigator)) {
        return undefined
    }

    if (typeof navigator.hid?.getDevices !== 'function') {
        return undefined
    }

    return navigator.hid.getDevices()
}

function getKeyboardLayout() {
    if (!('keyboard' in navigator)) {
        return undefined
    }

    return navigator.keyboard
        .getLayoutMap()
        .then(layoutMap => ({
            size: layoutMap.size,
            values: Array.from(layoutMap.values).join('|')
        }))
        .catch(error => {})
}

function getMediaDevices() {
    if (!('mediaDevices' in navigator) || !navigator.mediaDevices.enumerateDevices) {
        return undefined
    }

    return navigator.mediaDevices.enumerateDevices()
}

function getGamepads() {
    if (!('getGamepads') in navigator) {
        return undefined
    }

    try {
        return navigator.getGamepads()
    } catch (error) {}
}