import { getFuturePaymentStatus } from './api'
import { useQuery } from 'react-query'

async function getFuturePayment(contract) {
  let response = await getFuturePaymentStatus(
    contract.queryKey[0].contractNumber,
  )
  return response
}

function createFuturePaymentQuery(contract = {}) {
  return {
    queryFn: getFuturePayment,
    queryKey: [contract.request],
    ...contract,
  }
}

export function useFuturePayment(contract = {}) {
  return useQuery(
    createFuturePaymentQuery({
      refetchOnMount: 'always',
      ...contract,
    }),
  )
}
