import React from 'react'

import { Checkbox, Input } from '@chakra-ui/react'
import {
    CurrencyField,
    NumberField,
    SelectField
} from './fields'

export function getElementFromFieldName(fieldName) {
    if (fieldName === 'checkbox') return Checkbox
    if (fieldName === 'currency') return CurrencyField
    if (fieldName === 'number') return NumberField
    if (fieldName === 'select') return SelectField
    return Input
}