import React from 'react'
import { Flex, Heading } from '@chakra-ui/react'

import { ReactComponent as LockIcon } from 'assets/img/password-icon.svg'
import { IconHeader } from 'components'

export function PasswordRecoveryHeader() {
    return (
        <Flex align="center" direction="column" mb="2.5rem" w="100%">
            <IconHeader color="states.transparentMedium" icon={<LockIcon />}/>
            <Heading fontSize="1.5rem" mt="1rem">Password Reset</Heading>
        </Flex>
    )
}