function createTimer() {
    let startTime = 0

    function start() {
        startTime = performance.now()
    }

    function getTime(timestamp = null) {
        timestamp = timestamp ? timestamp : performance.now()
        return Math.max(Math.round(timestamp - startTime), 0)
    }

    function stop() {
        startTime = 0
    }

    function createInstance() {
        return createTimer()
    }

    return {
        start,
        stop,
        getTime,
        createInstance
    }
}

export const Timer = createTimer()