import {SeverityLevel} from '@microsoft/applicationinsights-web';

export function createLogger(instance) {
  let currentDefaultLogLevel = SeverityLevel.Information;

  function getLogLevel() {
    return currentDefaultLogLevel;
  }

  function setLogLevel(level) {
    if (!SeverityLevel[level]) {
      return;
    }

    currentDefaultLogLevel = level;
  }

  function createContext(severityLevel, trace = true) {
    return (message, properties = {}) => {
      if ((trace = false)) {
        instance.addBreadcrumb({message, severityLevel, ...properties});
      } else {
        instance.trace(message, {severityLevel, ...properties});

        // If the severity level of the trace is a Warning or greater (>= 2 -> (Warning|Error|Critical))
        // The we go ahead and add the trace logs to the breadcrumps as well
        if (severityLevel >= 2) {
          instance.addBreadcrumb({message, severityLevel, ...properties});
        }
      }
    };
  }

  const verbose = createContext(SeverityLevel.Verbose);
  const info = createContext(SeverityLevel.Info);
  const warn = createContext(SeverityLevel.Warning);
  const error = createContext(SeverityLevel.Error);
  const critical = createContext(SeverityLevel.Critical);

  const vCrumb = createContext(SeverityLevel.Verbose, false);
  const iCrumb = createContext(SeverityLevel.Info, false);
  const wCrumb = createContext(SeverityLevel.Warn, false);
  const eCrumb = createContext(SeverityLevel.Error, false);
  const criticalCrumb = createContext(SeverityLevel.Critical, false);

  function getDefaultLogFn(level, trace) {
    switch (level) {
      case SeverityLevel.Verbose:
        return trace ? verbose : vCrumb;
      case SeverityLevel.Info:
        return trace ? info : iCrumb;
      case SeverityLevel.Warn:
        return trace ? warn : wCrumb;
      case SeverityLevel.Error:
        return trace ? error : eCrumb;
      case SeverityLevel.Critical:
        return trace ? critical : criticalCrumb;
      default:
        return trace ? info : iCrumb;
    }
  }

  function log(message, properties = {}, trace = true) {
    getDefaultLogFn(currentDefaultLogLevel, trace)(message, properties);
  }

  const logger = {
    getLogLevel,
    setLogLevel,
    severityLevels: SeverityLevel,
    log,
    critical,
    criticalCrumb,
    error,
    eCrumb,
    info,
    iCrumb,
    verbose,
    vCrumb,
    warn,
    wCrumb,
  };

  instance.setLogger(logger);
  return logger;
}
