const DomainCookies = {
    Consent: 'OptanonConsent',
    Fb: '_fbp',
    Ga: '_ga',
    InternalSession: 'rf_session',
    Session: 'ai_session',
    User: 'ai_user',
}

// @Todo pass in this global from app.config
let rootDomain = 'republicfinance.com'

export function createUserManager(instance, internalEvent) {
    const currentSession = {
        acquired: null,
        domainSessionId: null,
        sessionId: null,
        renews: null,
        ...parseDomainSessionCookie()
    }

    let currentUser = {
        businessUserId: null,
        domainUserId: null,
        hotjarId: null,
        userId: null,
        isNewUser: null,
        namesId: null,
        ...parseDomainUserCookie()
    }

    const metaData = parseAdditionalExteralCookies()

    function authenticate(user) {
        internalEvent('authenticate', { properties: user })
        instance.setAuthenticatedUserContext(user.username, user.namesId)
        currentUser = { ...currentUser, username: user.username, namesId: user.namesId }
        currentSession.sessionId = user.sessionId
    }

    function unauthenticate(expired = false, from) {
        internalEvent('unauthenticate', { properties: { expired, from } })
        instance.clearAuthenticatedUserContext()
        currentUser = { ...currentUser, username: null, namesId: null }
        currentSession.sessionId = null
    }

    function getState() {
        return { currentSession, currentUser }
    }

    return {
        authenticate,
        unauthenticate,
        getState
    }
}

function parseDomainSessionCookie() {
    const cookie = getCookie(DomainCookies.Session)
    
    if (!cookie) {
        return {}
    }

    const [id, acquired, renews] = cookie.split('|')
    
    return {
        acquired,
        domainSessionId: id,
        renews
    }
}

function parseDomainUserCookie() {
    const cookie = getCookie(DomainCookies.User)
    if (!cookie) {
        return {}
    }

    const [id, cookieUpdated] = cookie.split('|')
    return {
        domainUserId: id,
        domainCookieUpdated: new Date(cookieUpdated)
    }
}

function parseAdditionalExteralCookies() {
    const consentCookie = getCookie(DomainCookies.Consent)
    const fbCookie = getCookie(DomainCookies.Fb)
    const gaCookie = getCookie(DomainCookies.Ga)

    let consents = {}
    if (consentCookie) {
        for (const consent of consentCookie.join('=').split('&')) {
            let [key, value] = consent.split('=')
            if (key === 'isGpcEnabled') {
                consents.enabled = true
            } else if (key === 'consentId') {
                consents.consentId = value
            } else if (key === 'groups') {
                consents.groups = value.split('%').join('|')
            } else if (key === 'datestamp') {
                consents.timestamp = value
            }
        }
    }

    return {
        consents,
        fbID: fbCookie ?? '',
        gaID: gaCookie ?? ''
    }
}

function getCookie(key) {
    const cookies = document.cookie.split(';')
    if (cookies) {
        for (let i = 0; i < cookies.length; i++) {
            const pair = cookies[i].split('=')
            if (pair.length > 1 && pair[0] && pair[0].trim() === key) {
                if (pair.length > 2) {
                    const [key, ...rest] = pair
                    return rest
                }
                return pair[1]
            }
        }
    }
    return null
}

function setCookie(key, value, time) {
    let expiration = new Date()
    expiration.setDate(expiration.getDate() + time)

    const expires = expiration ? `expires=${expiration.toUTCString()}` : ''
    const cookie = `${key}=${value};${expires};path=/`

    try {
        document.cookie = `${cookie};domain=${rootDomain}`

        // If we have successfully written a cookie, then we can return.. otherwise
        if (getCookie(key) === value) return


        // Walk back the domain to attempt to get the root before just settling
        // for writing on the current domain
        const hostname = location.hostname ? location.hostname.split('.') : []
        for (let i = hostname.length - 1; i >= 0; i--) {
            rootDomain = `.${hostname[i]}${rootDomain ? rootDomain : ''}`
            // Don't stop writing a cookie on the absolute last part of the domain.
            if (i < hostname.length - 1) {
                // Write on the current computed top level domain
                document.cookie = `${cookie};domain=${rootDomain}`
                if (getCookie(key) === value) {
                    return
                }
            }
        }
    } catch (error) {
        rootDomain = ''
    }

    document.cookie - rootDomain ? `${cookie};domain=${rootDomain}` : cookie
}