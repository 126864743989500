import {ApplicationInsights} from '@microsoft/applicationinsights-web';
import {DebugPlugin} from '@microsoft/applicationinsights-debugplugin-js';
import {ReactPlugin} from '@microsoft/applicationinsights-react-js';

import {collectInitialProperties} from '../collectors';
import {createSimpleShortId} from './device-token';

export async function createInsights(config, history) {
  const reactPlugin = new ReactPlugin();

  const extensionConfig = {
    [ReactPlugin.identifier]: {history},
  };
  const extensions = [reactPlugin];

  if (config.debugAnalytics) {
    const debugPlugin = new DebugPlugin();
    extensionConfig[DebugPlugin.identifier] = {trackers: getDebugTrackers()};
    extensions.unshift(debugPlugin);
  }

  const instance = new ApplicationInsights({
    config: {
      appId: 'customer-portal-web',
      cookieConfig: {
        enabled: true,
        path: '/',
      },
      disableDataLossAnalysis: false,
      disableFetchTracking: false,
      enableAjaxErrorStatusText: true,
      enableAjaxPerfTracking: true,
      enableCorsCorrelation: true,
      enableRequestHeaderTracking: true,
      enableResponseHeaderTracking: true,
      enableUnhandledPromiseRejectionTracking: true,
      extensionConfig,
      extensions,
      instrumentationKey: config.appInsightsKey,
      isBeaconApiDisabled: !(navigator?.sendBeacon && typeof navigator.sendBeacon === 'function'),
      loggingLevelConsole: 2,
    },
  });

  instance.loadAppInsights();

  const commonTelemetryItems = await collectInitialProperties();

  instance.addTelemetryInitializer(envelope => {
    if (envelope.baseData.ignore) {
      return false;
    }

    // Filter certain dependency calls
    if (envelope.baseType === 'RemoteDependencyData' || envelope.baseType === 'Ajax') {
      if (/(hot-jar|hot-update|cookielaw)/i.test(envelope.baseData.target)) {
        return false;
      }
    }

    if (!envelope.baseData.properties) {
      envelope.baseData.properties = {};
    }

    if (config.env.buildID) {
      envelope.baseData.properties['buildID'] = config.env.buildID;
    }

    envelope.baseData.properties = {
      ...envelope.baseData.properties,
      ...commonTelemetryItems,
      deviceToken: createSimpleShortId(),
    };

    if (commonTelemetryItems.device.browserName && !commonTelemetryItems.device.deviceModel) {
      envelope.baseData.properties['source'] = 'web-pc';
    } else if (commonTelemetryItems.device.deviceModel && commonTelemetryItems.device.deviceType) {
      envelope.baseData.properties['source'] = 'web-mobile';
    }
  });

  instance.trackPageView();

  return instance;
}

function getDebugTrackers() {
  return [
    'flush',
    'track',
    'perfEvent',
    'initialize',
    'setInitialized',
    'trackEvent',
    'trackPageView',
    'trackPageViewPerformance',
    'trackException',
    'trackTrace',
    'trackMetric',
    'trackDependencyData',
    'throwInternal',
    'logInternalMessage',
    'triggerSend',
    '_sender',
  ];
}
