import {MutationCache, QueryCache} from 'react-query';
import {analytics, logger} from '../analytics';

function formatKey(key) {
  if (!key) {
    return '';
  }

  if (Array.isArray(key)) {
    key = key.join('');
  }

  if (key.indexOf(',') >= 0) {
    return key.split(',').join('-');
  }

  return key;
}

const parseQuery = passedQuery => {
  const {cacheTime, meta, queryKey, state} = passedQuery;
  const {data, ...restOfState} = state;

  const parsed = {
    cacheTime,
    queryKey,
    state: restOfState,
  };

  if (meta) {
    parsed.meta = meta;
  }

  return parsed;
};

const parseMutation = passedMutation => {
  const {options, state} = passedMutation;
  return {options, state};
  // return (
  //   options.mutationKey,
  //   options.onSuccess,
  //   options.useErrorBoundary,
  //   state.context,
  //   state.data,
  //   state.error,
  //   state.failureCount,
  //   state.isPaused,
  //   state.status
  // );
};

export const queryCache = new QueryCache({
  onError: (error, queryEntry) => {
    const query = parseQuery(queryEntry);
    logger.error(`${query.queryKey} failed due to an error!`, {
      error,
      query,
    });
  },
  onSuccess: (data, queryEntry) => {
    const query = parseQuery(queryEntry);
    analytics.event(`query/${query.query}/success`, {data, query});
  },
});

export const mutationCache = new MutationCache({
  onError: (error, variables, context, mutationEntry) => {
    const mutation = parseMutation(mutationEntry);

    // logger.error(`${mutation.options?.mutationKey ?? 'unlabeled'} Mutation Failed with error`, {
    //   error,
    //   mutation,
    // });

    //working below
    logger.error(
      `${mutation.options?.mutationKey ?? 'unlabeled'} Mutation Failed with error`,
      {
        error,
      },
      mutation.options.useErrorBoundary,
      mutation.state.context,
      mutation.state.data,
      mutation.state.error,
      mutation.state.failureCount,
      mutation.state.isPaused,
      mutation.state.status,
    );
  },
  onSuccess: (data, variables, context, mutationEntry) => {
    const mutation = parseMutation(mutationEntry);
    analytics.event(`mutation/${mutation.options?.mutationKey ?? 'unlabeled'}/success`, {
      data,
      mutation,
    });
  },
});
