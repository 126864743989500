import React from 'react';
import {Heading, List, ListItem, Text} from '@chakra-ui/react';

import {CheckMarkIcon, SignupButton} from 'components/elements';

const BannerListItem = ({children}) => (
  <ListItem alignItems="center" display="flex" fontSize="1.4rem">
    <CheckMarkIcon />
    <Text>{children}</Text>
  </ListItem>
);

export function AccessBanner() {
  return (
    <div className="c-login-banner">
      <div className="c-login-banner__content">
        <Heading color="brand.blue" fontSize={{lg: '2.75rem'}}>
          Access your account 24/7 from{' '}
          <span className="c-promotional-text">anywhere!</span>
        </Heading>
        <List spacing={2} py={6}>
          <BannerListItem>
          Access your account online or with our mobile app
          </BannerListItem>
          <BannerListItem>
          Make payments with your debit card or bank account
          </BannerListItem>
          <BannerListItem>Manage your loan details in one place</BannerListItem>
          <BannerListItem>View your payment history</BannerListItem>
        </List>
        <SignupButton w="270px">Enroll in online access</SignupButton>
        <Text fontSize="0.875rem" mt="1rem">
          * Not all features are available for certain account statuses.
        </Text>
      </div>
    </div>
  );
}
