import {useMutation} from 'react-query';
import {axiosClient, queryClient} from 'core';

const DeclineReasons = {
  FundingAlreadyExists: 'FundingAlreadyExists',
  InvalidFundingInfo: 'InvalidFundingInfo',
};

const getReasonFromCode = code => {
  if (code === 2390) return DeclineReasons.InvalidFundingInfo;
  if (code === 8006) return DeclineReasons.FundingAlreadyExists;
  return 'Unknown';
};

const getMessageForReason = reason => {
  switch (reason) {
    case DeclineReasons.InvalidFundingInfo:
      return `We were unable to obtain authorization for payment with the routing or account number you provided. Please try a different payment method.`;
    case DeclineReasons.FundingAlreadyExists:
      return `This bank account already exists. Please select this account from your bank accounts or add a new account`;
    default:
      return `We ran into an issue adding your account. Please try again`;
  }
};

async function addBankFunding(newFundingAcccount) {
  const response = await axiosClient.post('/acct/api/payment/methods/bank', newFundingAcccount);
  const {
    message: {messageCode, messageText},
    ...payload
  } = response.data.payload;

  return {
    messageCode: Number(messageCode),
    messageText,
    ...payload,
  };
}

async function addBankFundingMutation(mutationData) {
  const {messageCode, messageText, ...response} = await addBankFunding(mutationData);

  if (messageCode < 0) {
    return {code: Math.abs(messageCode), message: messageText, status: 'FIELD_ERROR'};
  }

  if (messageCode === 0) {
    const newFundingAccount = {
      fundingAccount: response.fundingAccount,
      fundingSubType: response.accountType,
      isBankAccount: true,
      isExpired: false,
      tokenId: response.tokenId,
    };

    queryClient.setQueryData(['fundingAccounts'], previousAccounts => {
      return [...(previousAccounts || []), newFundingAccount];
    });

    return {newFundingAccount, status: 'SUCCESS'};
  }

  const declinedReason = getReasonFromCode(messageCode);
  const message = getMessageForReason(declinedReason);

  return {message, status: 'FORM_ERROR'};
}

export function useAddFundingMutation({extraArgs = {}, ...options} = {}) {
  return useMutation(
    mutationData =>
      addBankFundingMutation({
        ...mutationData,
        ...extraArgs,
      }),
    options,
  );
}
