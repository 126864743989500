import React, {
    createContext,
    useContext,
    useMemo,
} from 'react'

const FeaturesContext = createContext(null)

export function FeaturesProvider(props) {
    const { children, features } = props
    const contextValue = useMemo(() => features, [])
    return (
        <FeaturesContext.Provider value={contextValue}>
            {children}
        </FeaturesContext.Provider>
    )
}

export function useFeaturesContext() {
    return useContext(FeaturesContext)
}