import { parseDimension } from "../internal"

export function collectDisplay() {
    return {
        availableResolution: getResolution(screen.availWidth, screen.availHeight),
        colorDepth: screen.colorDepth,
        colorGamut: getColorGamut(),
        docCharacterSet: document.characterSet,
        forcedColors: areColorsForced(),
        hdr: isHDR(),
        invertedColors: areColorsInverted(),
        isFullScreenEnabled: document.fullscreenEnabled,
        monochromeDepth: getMonochromeDepth(),
        opener: window.opener,
        orientation: getOriginalOrientation(),
        pixelDepth: screen.pixelDepth,
        pixelRatio: window?.devicePixelRatio,
        resolution: getResolution(screen.width, screen.height),
        visibilityState: document.visibilityState,
        visualViewportScale: visualViewport?.scale,
        xdpi: screen?.deviceXDPI,
        ydpi: screen?.deviceYDPI
    }
}

function getResolution(width, height) {
    const dimensions = [parseDimension(width, null), parseDimension(height, null)]
    dimensions.sort().reverse()
    return dimensions
}

function matcher(matchString) {
    return matchMedia(`(${matchString})`).matches
}

function getColorGamut() {
    const gamuts = ['rec2020', 'p3', 'srgb']

    for (const gamut of gamuts) {
        if (matcher(`color-gamut: ${gamut}`)) {
            return gamut
        }
    }

    return undefined
}

function getMonochromeDepth() {
    if (!matcher('min-monochrome: 0')) {
        return undefined
    }

    for (let i = 0; i < 100; i++) {
        if (matcher(`max-monochrome: ${i}`)) {
            return i
        }
    }
}

function areColorsForced() {
    if (matcher('forced-colors: active')) {
        return true
    }
    if (matcher('forced-colors: none')) {
        return false
    }

    return undefined
}

function areColorsInverted() {
    if (matcher('inverted-colors: inverted')) {
        return true
    }
    if (matcher('inverted-colors: none')) {
        return false
    }

    return undefined
}

function isHDR() {
    if (matcher('dynamic-range: high')) {
        return true
    }
    if (matcher('dynamic-range: standard')) {
        return false
    }

    return undefined
}

function getOriginalOrientation() {
    if (!screen?.orientation) {
        return {}
    }

    return {
        orientationAngle: screen.orientation.angle,
        orientationType: screen.orientation.type
    }
}


