import React from 'react'
import { Center } from '@chakra-ui/react'
import { LoadingSpinner } from './LoadingSpinner'

export function FullScreenLoader() {
    return (
        <Center className="c-full-screen-loader" height="100%" width="100%">
            <LoadingSpinner size="120px" thickness="6px" />
        </Center>
    )
}