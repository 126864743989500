import { axiosClient } from 'core/httpClient'

export async function getContracts() {
  try {
    const response = await axiosClient.get('/acct/api/Contract')
    return response.data.payload
  } catch (error) {}
}

export async function getPaymentHistory(
  contractNumber,
  accountNumber,
  branchid,
) {
  const response = await axiosClient.get(
    `/acct/api/contract/PaymentHistory/${branchid}/${accountNumber}/${contractNumber}`,
  )
  return response.data.payload ?? []
}

export async function getBranch(branchId) {
  const response = await axiosClient.get(`/acct/api/branch/${branchId}`)
  return response.data.payload
}

export async function getConsent() {
  const response = await axiosClient.get(`/enrollment/api/consent`)
  return response.data.payload
}

export async function verifyEConsent(data) {
  const response = await axiosClient.post('/enrollment/api/consent', data)
  return response.data.payload
}

export async function updateEDelivery(data) {
  const response = await axiosClient.patch('/enrollment/api/consent', data)
  return response.data.payload
}

export async function submitHardship(data) {
  const response = await axiosClient.post(
    '/acct/api/hardship/questionnaire',
    data,
  )

  return response.data
}
export async function enrollStabilizer(data) {
  const response = await axiosClient.post(
    '/acct/api/hardship/StabilizerEnrollment',
    data,
  )

  return response.data
}
