import React from 'react'
import { Flex } from '@chakra-ui/react'

const backdropStyles = {
    align: 'center',
    bg: { lg: 'rgba(255, 255, 255, 0.6)' },
    direction: 'column',
    h: '100%',
    w: '100%'
}

export function ErrorBackdrop({ children, ...rest }) {
    return <Flex {...backdropStyles} {...rest}>{children}</Flex>
}