import React from 'react'
import { Box, Text } from '@chakra-ui/react'

export function InfoPanel({ children, title, ...rest }) {
    return (
        <Box border="2px" borderColor="brand.lightBlue" borderRadius="8px" padding={3} {...rest}>
            {title && <Text align={{ base: "left", md: "center" }}>{title}</Text>}
            {children}
        </Box>
    )
}