import React, { Fragment } from 'react'

import { RedirectToDebitButton } from './RedirectToDebitButton'

export function DebitOnlyPayment({
    isLoading,
    redirectToDebit
}) {
    return (
        <Fragment>
            <div className="col-md-4"></div>
            <div className="col-md-4">
                <RedirectToDebitButton isLoading={isLoading} redirectToDebit={redirectToDebit} />
            </div>
            <div className="col-md-4"></div>
        </Fragment>
    )
}