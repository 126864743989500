import React from 'react'
import { useHistory } from 'react-router-dom'

import { Text, PrimaryButton } from 'components/elements'

export function ResetInstructions({ username }) {
    const history = useHistory()
    const returnToLogin = () => {
        history.push('/login')
    }

    return (
        <div className="c-reset-instructions">
            <Text fontSize="xl">Check your email</Text>
            <Text align="center" fontSize="14px">
                Once we confirm {username} matches our records, we will send you a password
                recovery link. You should receive the email within the next few minutes.
            </Text>
            <div className="c-reset-info">
                <p className="c-reset-info__title">
                    If you do not receive an email, try the following
                </p>
                <ol className="c-reset-info__list">
                    <li>Check your spam or junk folder</li>
                    <li>Ensure no-reply@republicfinance.com is on your safe list</li>
                    <li>Double check that you provided a valid email address</li>                            
                </ol>
            </div>
            <PrimaryButton onClick={returnToLogin} maxW="400px">Back to Sign In</PrimaryButton>
        </div>
    )
}
