import React from "react";
import { Text } from "@chakra-ui/react";
import { useLocation, useParams } from "react-router-dom";

import { environment } from "core";
import { getRememberMeToken } from "services/auth";
import {
	Link,
	LogoImage,
	PageBackground,
	SignupButton,
	SupportPhoneLink,
} from "components/elements";

import { AccessBanner, LoginForm } from "./components";

import "./index.css";

export function Login() {
	const savedUsername = getRememberMeToken();
	const location = useLocation();
	const unlockAccountSuccess = location.state?.unlockAccountSuccess;

	return (
		<PageBackground breakpoint="lg" src="login-background.jpg">
			<div className="c-login-overlay">
				<AccessBanner />
				<div className="c-login-content">
					<LogoImage />
					<p id="loginFormHeader">Sign in to your account</p>
					<br></br>
					<p className="download-text">Download Our Mobile App Today !</p>
					<br></br>
					<div className="login-icons">
						<a
							href="https://apps.apple.com/us/app/republic-finance/id1409736823?ign-itscg=30200&ign-itsct=apps_box_link"
							target="_blank"
						>
							<img
								className="apple"
								alt="Download on the App Store"
								src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?&releaseDate=1485561600&h=3b3af0f58384332dc3d8b704c1e4f08f"
							></img>
						</a>
						<a
							href="https://play.google.com/store/apps/details?id=com.republicfinance&hl=en_US&gl=US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'> alt='Get it on Google Play'&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img alt='Get it on Google Play"
							target="_blank"
						>
							<img
								className="android"
								alt="Download on Google Play"
								src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
							></img>
						</a>
					</div>
					<LoginForm
						savedUsername={savedUsername}
						unlockAccountSuccess={unlockAccountSuccess}
					/>
					<div className="c-login-links">
						<Link to="/signin/forgot-password" id="forgotPasswordLink">
            Reset Password
						</Link>
						<SignupButton link mb={4} mt={2} id="signUpLink">
            Enroll in online access
						</SignupButton>
						<Text textStyle="supportText" id="customerSupportLabel">
							Customer Support: <SupportPhoneLink />
						</Text>
						<Text
							textStyle="supportText"
							id="customerSupportSchedule"
							style={{ textAlign: "center" }}
						>
							Monday - Thursday, 8 a.m.-8 p.m. ET
							<br />
							Friday, 8 a.m.-6 p.m. ET
							<br />
							Saturday, 9 a.m.-1 p.m. ET
						</Text>
					</div>
				</div>
			</div>
		</PageBackground>
	);
}
