import React from 'react'
import { Image as ChakraImage, chakra } from '@chakra-ui/react'


const Picture = chakra('picture')
const getImageSource = src => {
    const [imageFile, extension] = src.split('.')
    const baseURL = `${process.env.PUBLIC_URL}/Content/assets/img/${imageFile}`
    return {
        fallback: `${baseURL}.${extension}`,
        modern: `${baseURL}.webp`
    }
}

export function Image({ alt, children, sizes, src, ...rest }) {
    const { fallback, modern } = getImageSource(src)

    return (
        <Picture {...rest}>
            <source sizes={sizes} src={modern} />
            <ChakraImage sizes={sizes} src={fallback} w="100%" /> 
        </Picture>
    )
}