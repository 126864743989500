function createConfigFactory(serviceEndpoint, host) {
    return async labelFilter => {
        try {
            const response = await fetch(`${serviceEndpoint}/getInitialConfig`, {
                body: JSON.stringify({
                    filter: labelFilter,
                    host
                }),
                method: 'POST'
            })
            return response.json()
        } catch (error) {}
    } 
}

function formatConfig(passedConfig = {}) {
    const keys = Object.keys(passedConfig)
    
    if (!keys.length > 0) {
        return passedConfig
    }

    return keys.reduce((acc, curr) => {
        const entry = passedConfig[curr]
        if (typeof entry.value === 'object') {
            acc[entry.value.id] = entry    
        }
        return acc
    }, {})
}

export async function fetchConfigurationForEnvironment(buildEnv, configGroup, configServicingEndpoint) {
    if (!configGroup) {
        return {}
    }

    const configGetter = createConfigFactory(configServicingEndpoint, configGroup)
    const [sharedConfig, envConfig] = await Promise.all([
        configGetter(`shared.${buildEnv}*`),
        configGetter(`client.${buildEnv}*`)
    ])

    const config = {
        ...(sharedConfig || {}),
        ...(envConfig || {})
    }

    return formatConfig(config)
}
