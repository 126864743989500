import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { PasswordRecoveryHeader } from '../components'
import { ResetForm } from './ResetForm'

import './index.css'

export function ResetPassword({ history, location }) {
    const recoveryToken = location?.state?.recoveryToken
    const { token } = useParams()

    useEffect(() => {
        if (location.state == null) {
            const { path, state } = !!token
                ? { path: '/signin/reset-password', state: { recoveryToken: token }}
                : { path: '/login', state: {}}
            history.push(path, state)
        }
    }, [location.state, token])

    return (
        <div className="c-reset-password">
            <PasswordRecoveryHeader />
            <ResetForm recoveryToken={recoveryToken} />
        </div>
    )
}