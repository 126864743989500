import { fetchBaseConfig } from './fetchBaseConfig'
import { fetchConfigurationForEnvironment } from './fetchConfigurationForEnvironment'

export async function loadConfig(configURL) {
    const baseConfig = await fetchBaseConfig(configURL)
    const { configGroup, configServicingEndpoint, env: { buildEnv }} = baseConfig

    const environmentConfig = await fetchConfigurationForEnvironment(buildEnv, configGroup, configServicingEndpoint)

    return {
        config: baseConfig,
        features: environmentConfig
    }
}